import { differenceInDays, addDays, setHours, isAfter, subMinutes } from 'date-fns';

import island1 from '../../../images/courseImages/main_hall.png';
import island2 from '../../../images/courseImages/Infirmary.png';
import island3 from '../../../images/courseImages/rec_hall.png';
import island4 from '../../../images/courseImages/Observatory.png';
import island5 from '../../../images/courseImages/Cabins.png';
import island6 from '../../../images/courseImages/Trigger.png';
import island7 from '../../../images/courseImages/tech_hall.png';

export const initialSettings = {
    mustTakeInOrder: true,
    dashboardBackground: 'none',
    dashboardSolidColor: '#ffffff',
    lessonBackground: 'none',
    lessonSolidColor: '#ffffff',
};

export const islands = [
    { id: 1, image: island1 },
    { id: 2, image: island2 },
    { id: 3, image: island3 },
    { id: 4, image: island4 },
    { id: 5, image: island5 },
    { id: 6, image: island6 },
    { id: 7, image: island7 },
];

export const findIsland = id => {
    return islands.filter(is => is.id === id)[0].image;
};

export const timeSkipChecker = (moduleList, recentlyCompleted) => {
    const finishedFirstModule = moduleList.filter(module => module.id === 1);

    // have they completed at least module 1?
    if (finishedFirstModule.length === 1 && finishedFirstModule[0].isComplete) {
        // was it completed at least 7 days ago?
        // no? block and show info in tooltip
        const completedModules = moduleList.filter(mod => mod.isComplete).sort((a, b) => a.id - b.id);

        const mostRecentId = +completedModules[completedModules.length - 1].id;

        recentlyCompleted = Object.values(recentlyCompleted).filter(date => date);

        const lastCompleted = recentlyCompleted[recentlyCompleted.length - 1];

        /* The devTag timestamp coming back from our query needs to be adjusted
        for the local timezone */
        const now = new Date();
        const tzOffset = now.getTimezoneOffset();
        const mostRecentCompletedDate = subMinutes(new Date(lastCompleted), tzOffset);

        const dayDiff = differenceInDays(now, mostRecentCompletedDate);
        const lastCompletedPlus7 = addDays(mostRecentCompletedDate, 7);
        const opensAt = setHours(lastCompletedPlus7, 0);
        const isNextModuleOpen = isAfter(now, opensAt);

        const tooltipSettings = {
            lastModule: mostRecentId,
            dayDiff: Number.isNaN(dayDiff) ? 0 : dayDiff,
            nextModule: +mostRecentId + 1,
            completedModules: completedModules.length,
            isNextModuleOpen,
        };

        return tooltipSettings;
    }

    return {};
};
