import builder from './Builder';
import {
    icon,
    initialValues as builderInitialValues,
    getDataDefinitions,
    getDataConnectors,
    getDataConnectorsValues,
    getMetrics,
    getMetricsPrefix,
} from './Builder/settings.js';
import player from './Player';

import { previous7days } from '../../../../pages/TwoDayChecklist/helpers';
import _xor from 'lodash/xor';
import _isEmpty from 'lodash/isEmpty';

export default {
    code: 'campAir7Day',
    name: 'Camp Air 7 Day Checklist',
    labelField: 'text',
    icon,
    builder,
    builderInitialValues,
    player,
    getDataDefinitions,
    getDataConnectors,
    getDataConnectorsValues,
    getMetrics,
    getMetricsPrefix,
    onBeforeNextPage: async ({ answer }) => {
        if (!answer) {
            return Promise.reject('Please complete all 7 days before proceeding');
        }

        const completedDates = Object.values(answer)
            .filter(el => el.content)
            .map(el => el.date);

        // do we have answers for each of the previous 7 days?
        const samesies = _isEmpty(_xor(completedDates, previous7days));

        if (!samesies) {
            return Promise.reject('Please complete all 7 days before proceeding');
        }
    },

    hasPrintVersion: false,
};
