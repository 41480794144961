import { useQuery } from 'cccisd-react-query';
import { client } from 'cccisd-apollo';
import axios from 'cccisd-axios';

import dashboardQuery from './dashboardQuery.graphql';
import moduleProgressQuery from './moduleProgress.graphql';
import learnersQuery from './learnerList.graphql';
import oneLearnerQuery from './oneLearnerChecklists.graphql';
import triggerQuery from './triggers.graphql';
import confidenceQuery from './confidence.graphql';
import moduleStartQuery from './moduleStart.graphql';
import checklistReportQuery from './checklistReport.graphql';

const Boilerplate = window.cccisd.boilerplate;

const fetchCourse = async () => {
    const response = await client.query({
        query: dashboardQuery,
        variables: {},
        fetchPolicy: 'network-only',
    });
    return response;
};

const fetchModuleProgress = async pawnId => {
    const response = await client.query({
        query: moduleProgressQuery,
        variables: {
            pawnId,
        },
        fetchPolicy: 'network-only',
    });
    return response;
};

const fetchChecklists = async (pawnId, dates) => {
    const response = await axios.get(Boilerplate.route('get.campair.checklist'), {
        params: {
            pawnId,
            dates,
        },
    });
    return response;
};

const fetchLearners = async () => {
    const response = await client.query({
        query: learnersQuery,
        variables: {},
        fetchPolicy: 'network-only',
    });
    return response;
};

const fetchLearnerChecklists = async pawnId => {
    const response = await client.query({
        query: oneLearnerQuery,
        variables: {
            pawnId,
        },
        fetchPolicy: 'network-only',
    });
    return response;
};

const fetchTriggers = async pawnId => {
    const response = await client.query({
        query: triggerQuery,
        variables: {
            pawnId,
        },
        fetchPolicy: 'network-only',
    });
    return response;
};

const fetchConfidenceTotals = async pawnId => {
    const response = await client.query({
        query: confidenceQuery,
        variables: {
            pawnId,
        },
        fetchPolicy: 'network-only',
    });
    return response;
};

const fetchModuleStart = async pawnId => {
    const response = await client.query({
        query: moduleStartQuery,
        variables: {
            pawnId,
        },
        fetchPolicy: 'network-only',
    });
    return response;
};

const fetchChecklistReport = async flowHandle => {
    const response = await client.query({
        query: checklistReportQuery,
        variables: {
            flowHandle,
        },
        fetchPolicy: 'network-only',
    });
    return response;
};

export const useGetCourseQuery = (key = 'course') => useQuery([key], fetchCourse, { refetchOnWindowFocus: false });

export const useGetModuleProgressQuery = (key, pawnId) =>
    useQuery([key, pawnId], () => fetchModuleProgress(pawnId), {
        refetchOnWindowFocus: false,
    });

export const useGetChecklists = (key, pawnId, dates) =>
    useQuery([key, pawnId, dates], () => fetchChecklists(pawnId, dates), {
        refetchOnWindowFocus: false,
    });

export const useGetLearnersQuery = (key = 'learners') =>
    useQuery([key], () => fetchLearners(), {
        refetchOnWindowFocus: false,
    });

export const useGetLearnerChecklistsQuery = (key, pawnId) =>
    useQuery([key, pawnId], () => fetchLearnerChecklists(pawnId), {
        refetchOnWindowFocus: false,
    });

export const useGetTriggersQuery = (key, pawnId) =>
    useQuery([key, pawnId], () => fetchTriggers(pawnId), {
        refetchOnWindowFocus: false,
    });

export const useGetConfidenceTotalsQuery = (key, pawnId) =>
    useQuery([key, pawnId], () => fetchConfidenceTotals(pawnId), {
        refetchOnWindowFocus: false,
    });

export const useGetModuleStartQuery = (key, pawnId) =>
    useQuery([key, pawnId], () => fetchModuleStart(pawnId), {
        refetchOnWindowFocus: false,
    });

export const useGetChecklistReportQuery = (key, flowHandle) =>
    useQuery([key, flowHandle], () => fetchChecklistReport(flowHandle), {
        refetchOnWindowFocus: false,
    });
