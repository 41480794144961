import { reportTemplateBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {};

const Component = () => {
    return null;
};

// reportTemplateBuilder is a Formik wrapper which combines the Fields in this component with Filter and Widget Fields.
export default reportTemplateBuilder({})(Component);
