import React from 'react';
import IconWind from 'cccisd-icons/weather-windy';

export const fields = ['instructionalText', 'warningText'];

export const initialValues = {
    settings: '1 day flow',
};

export const icon = <IconWind />;

export const getDataDefinitions = {};

export const validate = values => {
    return {};
};
