import React from 'react';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page } from 'cccisd-laravel-report';
import { NotFound } from 'cccisd-laravel-boilerplate';
import Loader from 'cccisd-loader';

import style from './style.css';
import ReportHeader from '../../common/components/ReportHeader/index.js';
import { useGetTriggersQuery } from '../../../../../queries';
import TriggerInfo from './TriggerInfo';
import { triggerList } from './triggerList.js';
import Alert from '../../../../../components/Alert';

const Fortress = window.cccisd && window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;

const Component = () => {
    const getTriggers = useGetTriggersQuery('triggers', pawnId);

    let errorLayout = null;

    const isLoading = getTriggers.isLoading;
    const allQueryDataReturned = getTriggers.data;

    if (isLoading) {
        return <Loader loading={isLoading} />;
    }

    if (allQueryDataReturned) {
        const data = allQueryDataReturned.data.flows.assignmentProgress.devTags;

        const getKeyByValue = (object, value) => {
            return Object.entries(object).reduce((acc, [key, val]) => {
                if (val === value) {
                    acc.push(key);
                }
                return acc;
            }, []);
        };

        const res = getKeyByValue(data, '1');

        const pawnTriggerList = triggerList.filter(value => res.includes(value.id));

        return (
            <Report>
                <div className={style.reportBody}>
                    <Page>
                        <ReportHeader title="Understanding" allCaps="TRIGGERS" />
                        <div className={style.pageBody}>
                            {pawnTriggerList.length > 0 ? (
                                pawnTriggerList.map(trigger => {
                                    return (
                                        <TriggerInfo
                                            key={trigger.id}
                                            title={trigger.label}
                                            subtitle={trigger.subtitle}
                                            info={trigger.info}
                                        />
                                    );
                                })
                            ) : (
                                <Alert type="info" icon="info" text="Please select at least one trigger." />
                            )}
                        </div>
                    </Page>
                </div>
            </Report>
        );
    }

    if ([useGetTriggersQuery.isError].some(x => x === true)) {
        let ErrorLayout = errorLayout;

        return (
            <ErrorLayout>
                <NotFound />
            </ErrorLayout>
        );
    }
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({})(Component);
