import React from 'react';
import Loader from 'cccisd-loader';
import { NotFound } from 'cccisd-laravel-boilerplate';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';

import { useGetCourseQuery } from '../../queries';

const Fortress = window.cccisd && window.cccisd.fortress;

const Dashboard = () => {
    const getCourseQuery = useGetCourseQuery();

    let errorLayout = null;

    const isLoading = getCourseQuery.isLoading;
    const allQueryDataReturned = getCourseQuery.data;

    if (isLoading) {
        return <Loader loading={isLoading} />;
    }

    if (allQueryDataReturned) {
        const deploymentId = allQueryDataReturned.data.flows.deployment.deploymentId;

        return (
            <DeploymentPlayer
                deploymentId={deploymentId}
                pawnId={Fortress.user.acting.id}
                pawnHash={Fortress.user.acting.random_hash}
                mediatorProps={{}}
            />
        );
    }

    if ([getCourseQuery.isError].some(x => x === true)) {
        let ErrorLayout = errorLayout;

        return (
            <ErrorLayout>
                <NotFound />
            </ErrorLayout>
        );
    }
};

export default Dashboard;
