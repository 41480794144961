import React, { useState } from 'react';
import PropTypes from 'prop-types';

import style from './style.css';
import Retrospective from '../..';
import { getWarnings } from '../../../../pages/TwoDayChecklist/helpers.js';

import IconCheckCircle from 'cccisd-icons/checkmark-circle';
import IconWarning from 'cccisd-icons/notification2';

const WarningScreen = props => {
    const [showRetrospective, setShowRetrospective] = useState(false);

    const { closeForm, sevenDayTotals, thirtyDayTotals } = props;

    const totals = getWarnings(sevenDayTotals, thirtyDayTotals);

    const returnToRetro = () => {
        setShowRetrospective(true);
        closeForm();
    };

    return (
        <>
            {!showRetrospective && (
                <div className={style.warning}>
                    {totals.length > 0 ? (
                        <div>
                            You reported:
                            {totals.map(warning => (
                                <div key={warning.id} className={style.label}>
                                    <div>{warning.thumbnail}</div>
                                    <div className={style.text}>
                                        <div className={style.id}>{warning.text}</div>
                                        <div className={style.daysExperienced}>
                                            <span style={{ fontSize: '26px' }}>{warning.daysExperienced}</span> times in{' '}
                                            {warning.totalDays} days
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className={style.icon}>
                                <IconWarning />
                            </div>
                            <div>
                                This is a warning sign that your asthma is out of control and you should see your
                                healthcare provider.
                            </div>
                            <div className={style.okButton}>
                                <button
                                    type="button"
                                    onClick={() => setShowRetrospective(true)}
                                    className={`btn btn-primary ${style.actionButton}`}
                                >
                                    <IconCheckCircle spaceRight />
                                    OK
                                </button>
                            </div>
                        </div>
                    ) : (
                        returnToRetro()
                    )}
                </div>
            )}
            {showRetrospective && <Retrospective days={2} />}
        </>
    );
};

export default WarningScreen;

WarningScreen.propTypes = {
    closeForm: PropTypes.func,
    sevenDayTotals: PropTypes.object,
    thirtyDayTotals: PropTypes.object,
};
