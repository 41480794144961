import React from 'react';
import useWithModulesMediator from 'cccisd-laravel-assignment/dist/components/navigations/withModules/Mediator/useWithModulesMediator.js';
import { DefaultPlayerSettingsContext } from 'cccisd-laravel-flow';
import Loader from 'cccisd-loader';
import Tabs from 'cccisd-tabs';
import { NotFound } from 'cccisd-laravel-boilerplate';

import { useGetModuleProgressQuery } from '../../queries';
import style from './style.css';
import { findIsland, initialSettings, timeSkipChecker } from './helpers.js';
import LessonCard from './LessonCard';

import CheckCircle from 'cccisd-icons/checkmark-circle';

const backgroundColor = '#f0e9dc';
const Fortress = window.cccisd && window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;

const Mediator = props => {
    let { deploymentId, isNetworkError, mediatorProps } = props;

    mediatorProps = mediatorProps || {};

    const settings = {
        ...initialSettings,
        ...mediatorProps,
    };

    const { mustTakeInOrder } = settings;

    const { currentFlowProps, modules } = useWithModulesMediator({
        ...props,
        mustTakeInOrder,
    });

    const getProgressQuery = useGetModuleProgressQuery('progress', pawnId);

    let errorLayout = null;

    const isLoading = getProgressQuery.isLoading;
    const allQueryDataReturned = getProgressQuery.data;

    const currentModule = modules.find(m => m.isActive);

    if (isLoading || !currentModule) {
        return <Loader loading={isLoading} removeChildren={!isNetworkError} />;
    }

    if (allQueryDataReturned) {
        const completedModules = allQueryDataReturned.data.flows.assignmentProgress.devTags;
        delete completedModules.__typename;

        const recentlyCompleted = Object.values(completedModules).filter(date => date).length;

        const renderModuleView = () => {
            return (
                <div className="container">
                    <div className="row">
                        <div className={style.moduleProgress}>
                            <Tabs
                                tabList={partPicker()}
                                initialTab={`${completedModules?.Part7Ended ? 7 : currentModule.id}`}
                                orientation="vertical"
                                verticalTabWidths={{ left: 4, right: 8 }}
                                onTabSelect={() => getProgressQuery.refetch()}
                            />
                        </div>
                    </div>
                </div>
            );
        };

        const partPicker = () => {
            const tooltipSettings = timeSkipChecker(modules, completedModules);

            return modules.map(module => {
                const renderResources = () => {
                    if (module.id === 1 && recentlyCompleted < 3) {
                        return false;
                    }

                    if (!module.isAllowed) {
                        return false;
                    }
                    if (module.isResume || module.isComplete) return true;

                    if (recentlyCompleted < module.id) {
                        return true;
                    }
                };

                return {
                    name: `${module.id}`,
                    title: (
                        <div
                            onClick={() => module.setActive()}
                            style={
                                module.isAllowed
                                    ? { color: '#7f1637', fontWeight: 'bold' }
                                    : { color: '#ccc', cursor: 'not-allowed' }
                            }
                            className={style.partPicker}
                        >
                            {module.isComplete && (
                                <span className={style.completeCheck}>
                                    <CheckCircle />
                                </span>
                            )}
                            <div
                                style={{
                                    backgroundImage: `url(${findIsland(module.id)})`,
                                    width: '40px',
                                    height: '40px',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                }}
                                className={style.tabIsland}
                            />
                            <div>
                                <div>{`Part ${module.id}`}</div>
                                <div className={style.tabLabel}>{module.title}</div>
                            </div>
                        </div>
                    ),
                    content: (
                        <div>
                            {module.lessons.map(lesson => {
                                return (
                                    <LessonCard
                                        backgroundColor={backgroundColor}
                                        currentFlowProps={currentFlowProps}
                                        deploymentId={deploymentId}
                                        getProgressQuery={getProgressQuery.refetch}
                                        isNetworkError={isNetworkError}
                                        key={lesson.flowId}
                                        lesson={lesson}
                                        module={module}
                                        showReport={module.id !== 1 && (module.isAllowed || module.isResume)}
                                        showResources={renderResources()}
                                        tooltipSettings={tooltipSettings}
                                    />
                                );
                            })}
                        </div>
                    ),
                };
            });
        };

        return (
            <DefaultPlayerSettingsContext.Provider
                value={{
                    background: 'solidColor',
                    backgroundColor,
                }}
            >
                {renderModuleView()}
            </DefaultPlayerSettingsContext.Provider>
        );
    }

    if ([getProgressQuery.isError].some(x => x === true) || isNetworkError) {
        let ErrorLayout = errorLayout;

        return (
            <ErrorLayout>
                <NotFound />
            </ErrorLayout>
        );
    }
};

export default Mediator;
