import React from 'react';
import IconWind from 'cccisd-icons/weather-windy';

export const fields = [];

export const initialValues = {};

export const icon = <IconWind />;

export const validate = values => {
    return {};
};

export const getDataConnectors = settings => {
    return {
        triggerList: {
            type: 'string',
            title: 'Environmental triggers in last 30 days as bullet list',
        },
    };
};

export const getDataConnectorsValues = (settings, answer, language = 'en') => {
    const list = Object.values(answer);

    const triggerObject = list[0].content.triggersMoreThanZeroInThirtyDays;

    const triggers = Object.keys(triggerObject);

    const bulletedList = triggers.length > 0 && triggers.map(option => `<li>${option}</li>`).join('');

    return {
        triggerList() {
            if (!answer || !bulletedList) {
                return '';
            }

            return `<ul>${bulletedList}</ul>`;
        },
    };
};

export const getMetricsPrefix = () => {
    return 'retro';
};

export const getMetrics = (id, settings, answer) => {
    if (answer === undefined) {
        return {
            [id + '_answered']: null,
        };
    }

    const completedDates = Object.values(answer).filter(day => day.content.completeUponArrivalTo7Day);

    if (completedDates.length > 0) {
        return {
            [id + '_answered']: completedDates[0].content.completeUponArrivalTo7Day,
        };
    }
    return {
        [id + '_answered']: 0,
    };
};

export const getDataDefinitions = id => {
    const metricsPrefix = getMetricsPrefix();

    const data = {
        title: 'Camp Air Retrospective',
        titleHtml: 'Camp Air Retrospective',
        type: 'number',
        questionType: 'campair_retro',
        variableType: 'answer',
        tags: {
            system: ['answer'],
        },
        minimum: 0,
        maximum: 7,
    };

    return {
        [id + '_answered']: {
            ...data,
            reportHeader: `${metricsPrefix}_${id}_answered`,
        },
    };
};
