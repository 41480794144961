import React from 'react';
import PropTypes from 'prop-types';
import { Nav as SecondaryNav } from 'cccisd-laravel-appdefs';

import style from './style.css';

const AppDefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

export default class MainLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        showSecondaryNav: PropTypes.bool,
    };

    static defaultProps = {
        className: 'container',
        showSecondaryNav: false,
    };

    render() {
        return (
            <div className={style.wrapper}>
                <div className={style.background}>
                    <div>
                        {this.props.showSecondaryNav && Fortress.auth() && (
                            <SecondaryNav
                                className={this.props.className}
                                navs={AppDefs.navs}
                                routes={AppDefs.routes}
                            />
                        )}
                        <div className={this.props.className}>{this.props.children}</div>
                    </div>
                    <div className={style.footer}>
                        Website and software developed by{' '}
                        <a href="//www.3cisd.com" rel="noopener noreferrer" target="_blank">
                            3C Institute{' '}
                        </a>
                        • Copyright © {new Date().getFullYear()} •{' '}
                        <a href="//www.3cisd.com/privacy-policy" rel="noopener noreferrer" target="_blank">
                            Privacy Policy
                        </a>{' '}
                        •{' '}
                        <a href="//www.3cisd.com/terms-of-use" rel="noopener noreferrer" target="_blank">
                            Terms of Use
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
