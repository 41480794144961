import React from 'react';
import { Progress } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import { NotFound } from 'cccisd-laravel-boilerplate';

import { useGetCourseQuery } from '../../queries';

const ProgressTable = () => {
    const getCourseQuery = useGetCourseQuery();

    let errorLayout = null;

    const isLoading = getCourseQuery.isLoading;
    const allQueryDataReturned = getCourseQuery.data;

    if (isLoading) {
        return <Loader loading={isLoading} />;
    }

    if (allQueryDataReturned) {
        const deploymentId = allQueryDataReturned.data.flows.deployment.deploymentId;
        const assignmentId = allQueryDataReturned.data.flows.deployment.assignment.assignmentId;
        const groupId = allQueryDataReturned.data.flows.deployment.assignment.groupId;

        return (
            <>
                <h1>Progress</h1>
                <Progress
                    assignmentId={assignmentId}
                    deploymentId={deploymentId}
                    projectId={groupId}
                    showByWhitelist={['survey', 'assignment']}
                    labelForSurvey="Parts"
                    hideSystemId
                    hideDeploymentDropdown
                    hideProjectDropdown
                    hideQuestDropdown
                />
            </>
        );
    }

    if ([getCourseQuery.isError].some(x => x === true)) {
        let ErrorLayout = errorLayout;

        return (
            <ErrorLayout>
                <NotFound />
            </ErrorLayout>
        );
    }
};

export default ProgressTable;
