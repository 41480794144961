import { format } from 'date-fns';
import builder from './Builder';
import { icon, initialValues as builderInitialValues, getDataDefinitions } from './Builder/settings.js';
import player from './Player';

export default {
    code: 'campAir1Day',
    name: 'Camp Air 1 Day Checklist',
    labelField: 'text',
    icon,
    builder,
    builderInitialValues,
    player,
    getDataDefinitions,
    onBeforeNextPage: async ({ answer }) => {
        const today = format(new Date(), 'yyyy-MM-dd');

        const todayCompleted = Object.values(answer).filter(el => el.content && el.date === today).length === 1;

        if (!answer || !todayCompleted) {
            return Promise.reject("Please complete today's checklist before proceeding");
        }
    },
    hasPrintVersion: false,
};
