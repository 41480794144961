import React from 'react';

import style from './style.css';
import Retrospective from '../../components/Retrospective';

const TwoDayChecklist = () => {
    return (
        <div className={style.content}>
            <div className={style.ipad}>
                <div className={style.tracker} aria-label="scrollable symptom tracker" role="presentation">
                    <Retrospective days={2} />
                </div>
            </div>
            <div className={style.instructions} id="text">
                <div className="htmlpage-block-content" data-blockname="dailychecklist_text">
                    <p>
                        {`Days you still need to complete will be yellow. Days you've completed
                        will be blue.`}
                    </p>
                    <p>
                        {`When you select a day, you'll see rows of yes/no prompts. Select the
                        arrow on each row to see the full question or select Expand All to see all
                        of the questions at once.`}
                    </p>
                    <p>
                        {`Answer YES or NO for each question. For the medicine questions, you can
                        select IDK if you're not sure what kind of medicine you're taking.
                        The Environment question asks about triggers. If you haven't been
                        around any triggers, select NO. If you have been around triggers, select YES
                        and then choose each trigger you've been around by selecting the YES
                        button that appears next to that trigger. You don't need to select
                        anything for triggers you haven't been around.`}
                    </p>
                    <p>Select the DONE button once you&apos;ve answered all of the questions.</p>
                </div>
            </div>
        </div>
    );
};

export default TwoDayChecklist;
