import React from 'react';
import PropTypes from 'prop-types';
import { builderWrapper } from 'cccisd-survey-common';
import { fields, validate, initialValues } from './settings.js';

export class Builder extends React.Component {
    static propTypes = {
        fields: PropTypes.object,
    };

    render() {
        return <div>Use this flow in Part 1.</div>;
    }
}

export default builderWrapper({
    fields,
    validate,
    initialValues,
    withTemplate: false,
    withAccommodations: false,
})(Builder);
