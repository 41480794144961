import React from 'react';
import { format, subDays } from 'date-fns';

import symptoms from '../../../images/symptomTracker/symptoms.png';
import night from '../../../images/symptomTracker/night.png';
import controller from '../../../images/symptomTracker/controller.png';
import quickReliever from '../../../images/symptomTracker/quick_reliever.png';
import interruptions from '../../../images/symptomTracker/interruptions.png';
import activity from '../../../images/symptomTracker/activity.png';
import environment from '../../../images/symptomTracker/environment.png';

import warningSymptoms from '../../../images/symptomTracker/warning/warningSymptoms.png';
import warningNight from '../../../images/symptomTracker/warning/warningNight.png';

const symptomImg = <img src={warningSymptoms} alt="symptoms thumbnail" height="96px" width="96px" />;
const nightImg = <img src={warningNight} alt="night thumbnail" height="96px" width="96px" />;

import IconPower from 'cccisd-icons/power2';

const width = '25vh';
const height = '25vh';

export const previous7days = [0, 1, 2, 3, 4, 5, 6].map(i => {
    const timeStamp = subDays(new Date(), i);

    return format(timeStamp, 'yyyy-MM-dd');
});

export const prev30days = [...Array(30).keys()].map(i => {
    const timeStamp = subDays(new Date(), i);

    return format(timeStamp, 'yyyy-MM-dd');
});

export const triggers = [
    {
        id: 7,
        img: <IconPower />,
        name: 'Smoke',
        inner: 'Examples: cigarette, cigar, marijuana, or hookah smoke, e-cigarette vapor, or wood smoke or fires',
    },
    {
        id: 8,
        img: <IconPower />,
        name: 'Weather',
        inner: 'Cold air, heat, or thunderstorms',
    },
    {
        id: 9,
        img: <IconPower />,
        name: 'Roaches',
        inner: 'Roaches in your home or elsewhere',
    },
    {
        id: 10,
        img: <IconPower />,
        name: 'Dust/dust mites',
        inner: 'Found in bedding, cloth-covered furniture, carpets, curtains',
    },
    {
        id: 11,
        img: <IconPower />,
        name: 'Animals',
        inner: 'Examples: cats, dogs, horses, cows, mice, rats, birds',
    },
    {
        id: 12,
        img: <IconPower />,
        name: 'Feathers',
        inner: 'Down jackets or bedding',
    },
    {
        id: 13,
        img: <IconPower />,
        name: 'Mold',
        inner: 'Inside or outside',
    },
    {
        id: 14,
        img: <IconPower />,
        name: 'Air pollution',
        inner: 'Examples: smog and car exhaust',
    },
    {
        id: 15,
        img: <IconPower />,
        name: 'Pollen',
        inner: 'Powder from trees, grasses, and weeds',
    },
    {
        id: 16,
        img: <IconPower />,
        name: 'Strong odors',
        inner: 'Examples: hairspray, air fresheners, cleaning products, pig/cow farm, waste/dump site',
    },
    {
        id: 17,
        img: <IconPower />,
        name: 'Pesticides',
        inner: 'Pesticides or other chemicals',
    },
    {
        id: 18,
        img: <IconPower />,
        name: 'Gas heat sources',
        inner: 'Wall heater, stove/oven',
    },
    {
        id: 19,
        img: <IconPower />,
        name: 'Had flu or cold',
        inner: 'Flu, cold, or upper respiratory infection',
    },
    {
        id: 20,
        img: <IconPower />,
        name: 'Other',
        inner: 'Any other environmental trigger',
    },
];

export const generateInitialValues = () => {
    const initialValues = {};

    triggers
        .map(trigger => {
            return trigger.name;
        })
        .map(name => {
            if (name) {
                initialValues[name] = 'No';
            }
            return null;
        });

    return initialValues;
};

export const items = [
    {
        id: 0,
        name: 'Symptoms',
        img: <img src={symptoms} alt="symptoms thumbnail" height={height} width={width} />,
        class: 'mainItem',
        btns: 2,
        inner: 'Did you wheeze, cough, have shortness of breath, or have chest tightness during the day today?',
    },
    {
        id: 1,
        name: 'Waking at night',
        img: <img src={night} alt="night thumbnail" height={height} width={width} />,
        class: 'mainItem',
        btns: 2,
        inner: 'Did you wake up last night because of wheezing, coughing, shortness of breath, or chest tightness?',
    },
    {
        id: 2,
        name: 'Controller med',
        img: <img src={controller} alt="symptoms thumbnail" height={height} width={width} />,
        class: 'mainItem',
        btns: 3,
        inner: (
            <>
                Did you take your controller medicine? This is the medicine you{' '}
                <strong>
                    <i>take every day</i>{' '}
                </strong>
                even if you are{' '}
                <strong>
                    <i>not having any symptoms</i>
                </strong>
                . Some controller medicines are:
                <ul>
                    {' '}
                    <li>Flovent</li> <li>Pulmicort</li> <li>Advair</li> <li>QVar</li> <li>Symbicort</li>{' '}
                    <li>Singulair</li>{' '}
                </ul>
                <br />
                If you don&apos;t know what asthma medicine you&apos;re taking, check your medicine or ask your parents
                what you take.
            </>
        ),
    },
    {
        id: 3,
        name: 'Quick reliever',
        img: <img src={quickReliever} alt="symptoms thumbnail" height={height} width={width} />,
        class: 'mainItem',
        btns: 3,
        inner: (
            <div>
                Did you take your quick-relief or rescue inhaler (pump) to{' '}
                <strong>
                    <i>treat</i>
                </strong>{' '}
                symptoms you were{' '}
                <strong>
                    <i>already having</i>
                </strong>
                , such as wheezing, coughing, shortness of breath, or chest tightness? Some quick-relief or rescue
                medicines are:
                <ul>
                    <li>ProAir</li>
                    <li>Ventolin</li>
                    <li>Proventil</li>
                    <li>RespiClick</li>
                    <li>Albuterol</li>
                </ul>
                <br />
                If you don&apos;t know what asthma medicine you&apos;re taking, check your medicine or ask your parents
                what you take.
            </div>
        ),
    },
    {
        id: 4,
        name: 'Interruptions',
        img: <img src={interruptions} alt="symptoms thumbnail" height={height} width={width} />,
        class: 'mainItem',
        btns: 2,
        inner: 'Did wheezing, coughing, chest tightness, or shortness of breath stop you from going to school or work or doing your other normal activities today?',
    },
    {
        id: 5,
        name: 'Physical activity',
        img: <img src={activity} alt="symptoms thumbnail" height={height} width={width} />,
        class: 'mainItem',
        btns: 2,
        inner: 'Did you do any physical activity like running, dancing, or climbing stairs that made you wheeze, cough, have chest tightness, or get short of breath?',
    },
    {
        id: 6,
        name: 'Environment',
        img: <img src={environment} alt="symptoms thumbnail" height={height} width={width} />,
        class: 'mainItem',
        btns: 2,
        inner: (
            <div>
                Was there anything today that made you wheeze, cough, have chest tightness, or get short of breath?
            </div>
        ),
    },
];

export const getWarnings = (last7, last30) => {
    return [
        {
            id: 'symptoms',
            text: 'Having asthma symptoms',
            daysExperienced: last7.Symptoms >= 3 ? last7.Symptoms : null,
            thumbnail: symptomImg,
            totalDays: last7.Symptoms >= 3 ? 7 : null,
        },
        {
            id: 'waking',
            text: 'Waking up at night',
            daysExperienced: last30['Waking at night'] >= 3 ? last30['Waking at night'] : null,
            thumbnail: nightImg,
            totalDays: last30['Waking at night'] >= 3 ? 30 : null,
        },
        {
            id: 'interruptions',
            text: 'Having to stop an activity',
            daysExperienced: last7.Interruptions >= 3 ? last7.Interruptions : null,
            thumbnail: symptomImg,
            totalDays: last7.Interruptions >= 3 ? 7 : null,
        },
    ].filter(warning => warning.daysExperienced);
};

export const reportItems = [...items, ...triggers].map(item => {
    return item.name;
});

export const triggerItems = triggers.map(item => {
    return item.name;
});
