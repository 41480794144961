import React from 'react';
import Header from 'cccisd-header';

import style from './style.css';

function AppHeader() {
    return (
        <Header
            containerClassName="container-fluid"
            wrapperClassName={style.wrapper}
            logoClassName={style.logo}
            secondaryNavLinks="mainNav"
            showAlertAction={false}
            showHomeAction={false}
        />
    );
}

export default AppHeader;
