import React from 'react';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page } from 'cccisd-laravel-report';
import Loader from 'cccisd-loader';
import { NotFound } from 'cccisd-laravel-boilerplate';

import style from './style.css';
import ReportHeader from '../../common/components/ReportHeader/index.js';
import teyana from '../../../../../../images/reportImages/frog.png';
import { useGetConfidenceTotalsQuery } from '../../../../../queries';

const Fortress = window.cccisd && window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;

const Component = () => {
    const getConfidenceTotals = useGetConfidenceTotalsQuery('triggers', pawnId);

    let errorLayout = null;

    const isLoading = getConfidenceTotals.isLoading;
    const allQueryDataReturned = getConfidenceTotals.data;

    if (isLoading) {
        return <Loader loading={isLoading} />;
    }

    if (allQueryDataReturned) {
        const data = allQueryDataReturned.data.flows.assignmentProgress.devTags;

        const responses = Object.values(data);

        const getOccurrence = (array, value) => {
            return array.filter(v => v === value).length;
        };

        const veries = getOccurrence(responses, 'Very');
        const littles = getOccurrence(responses, 'A little');
        const notAtAlls = getOccurrence(responses, 'Not at all');

        const getReportText = () => {
            if (veries > 0 && littles === 0 && notAtAlls === 0) {
                return "It sounds like you're pretty sure you can do the things suggested. That's great!";
            }
            if (notAtAlls > 0 && littles === 0 && veries === 0) {
                return "It sounds like you're not sure you can do the things suggested. Hopefully this program will help you gain confidence about trying these things.";
            }

            return 'It sounds like you think you can do some things. Hopefully this program will help you gain confidence about trying them.';
        };

        return (
            <Report>
                <div className={style.reportBody}>
                    <Page>
                        <ReportHeader title="Confidence" allCaps="REPORT" />
                        <div className={style.pageBody}>
                            <div className={style.reportText}>
                                <div className={style.feedback}>{getReportText()}</div>
                            </div>
                            <div className={style.image}>
                                <img src={teyana} alt="teyana the frog" />
                            </div>
                        </div>
                    </Page>
                </div>
            </Report>
        );
    }

    if ([useGetConfidenceTotalsQuery.isError].some(x => x === true)) {
        let ErrorLayout = errorLayout;

        return (
            <ErrorLayout>
                <NotFound />
            </ErrorLayout>
        );
    }
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({})(Component);
