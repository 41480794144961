import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const TrackerTable = props => {
    const { columns, data, moreInfo, subtitle, title } = props;

    return (
        <div>
            <h4>{title}</h4>
            {subtitle}
            <div style={{ marginTop: '1em' }}>
                <Table columns={columns} data={data} showPerPageOptions={false} hideShowingRowsSummary />
            </div>
            <div style={{ marginTop: '1em' }}>{moreInfo && moreInfo}</div>
        </div>
    );
};

TrackerTable.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    title: PropTypes.string,
    subtitle: PropTypes.object,
    moreInfo: PropTypes.object,
};

export default TrackerTable;
