import React from 'react';
import PropTypes from 'prop-types';

import style from '../style.css';

const TriggerInfo = props => {
    const { title, subtitle, info } = props;

    return (
        <div className={style.container}>
            <div className={style.label}>
                <h4 className={style.title}>{title}</h4>
                {subtitle && <div>{subtitle}</div>}
            </div>
            <div className={style.info}>{info}</div>
        </div>
    );
};

TriggerInfo.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    info: PropTypes.object,
};

export default TriggerInfo;
