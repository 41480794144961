import React from 'react';
import Loader from 'cccisd-loader';
import { NotFound } from 'cccisd-laravel-boilerplate';
import { Formik, Form, Field, CccisdSelect } from 'cccisd-formik';
import Table from 'cccisd-table';

import { useGetLearnersQuery } from '../../queries';
import { reportItems } from '../TwoDayChecklist/helpers';
import Alert from '../../components/Alert';

function DataTable(props) {
    const {
        selectedLearner: {
            checklists,
            user: { firstName, lastName },
            pawn: { pawnId },
        },
    } = props;

    const columnNames = reportItems.map(item => {
        return {
            name: item,
            label: item,
            filter: true,
            sort: true,
            topHeader: item === 'Smoke' ? 'Triggers' : null,
            topHeaderSpan: item === 'Smoke' ? 14 : null,
        };
    });

    const data = checklists.map(checklist => {
        return {
            id: checklist.date,
            Symptoms: JSON.parse(checklist.content).Symptoms,
            'Waking at night': JSON.parse(checklist.content)['Waking at night'],
            'Controller med': JSON.parse(checklist.content)['Controller med'],
            'Quick reliever': JSON.parse(checklist.content)['Quick reliever'],
            Interruptions: JSON.parse(checklist.content).Interruptions,
            'Physical activity': JSON.parse(checklist.content)['Physical activity'],
            Environment: JSON.parse(checklist.content).Environment,
            Smoke: JSON.parse(checklist.content).Smoke,
            Weather: JSON.parse(checklist.content).Weather,
            Roaches: JSON.parse(checklist.content).Roaches,
            'Dust/dust mites': JSON.parse(checklist.content)['Dust/dust mites'],
            Animals: JSON.parse(checklist.content).Animals,
            Feathers: JSON.parse(checklist.content).Feathers,
            Mold: JSON.parse(checklist.content).Mold,
            'Air pollution': JSON.parse(checklist.content)['Air pollution'],
            Pollen: JSON.parse(checklist.content).Pollen,
            'Strong odors': JSON.parse(checklist.content)['Strong odors'],
            Pesticides: JSON.parse(checklist.content).Pesticides,
            'Gas heat sources': JSON.parse(checklist.content)['Gas heat sources'],
            'Had flu or cold': JSON.parse(checklist.content)['Had flu or cold'],
            Other: JSON.parse(checklist.content).Other,
        };
    });

    const columns = [
        { name: 'id', label: 'Dates', filter: true, sort: true, filterSettings: { type: 'date' } },
        ...columnNames,
    ];

    if (checklists.length === 0) {
        return <Alert type="info" icon="info" text=" This participant has not completed any Checklists yet" />;
    }

    return (
        <Table
            columns={columns}
            data={data}
            isCsvDownload
            csvFilename={`checklists for pawnId: ${pawnId} ${firstName} ${lastName}`}
        />
    );
}

const LearnerChecklistData = () => {
    const getLearnersQuery = useGetLearnersQuery();

    let errorLayout = null;

    const isLoading = getLearnersQuery.isLoading;
    const allQueryDataReturned = getLearnersQuery.data;

    if (isLoading) {
        return <Loader loading={isLoading} />;
    }

    if (allQueryDataReturned.data.groups.classList.length < 1) {
        return <Alert type="danger" icon="warning" text="Please roster at least one Class" />;
    }

    if (allQueryDataReturned) {
        const classList = allQueryDataReturned.data.groups.classList;

        const classOptions = classList.map(cl => {
            return {
                key: cl.group.groupId,
                value: cl.group.groupId,
                label: cl.group.label,
                learners: cl.childRoles.learnerList.map(learner => {
                    return {
                        key: learner.pawn.pawnId,
                        value: learner.pawn.pawnId,
                        label: `${learner.user.lastName}, ${learner.user.firstName}`,
                        checklists: learner.checklists,
                    };
                }),
            };
        });

        const getLearnerOptions = (classArr, classId) => {
            let selectedClass = classArr.filter(cl => cl.value === classId);

            if (selectedClass.length === 1) {
                return selectedClass[0].learners;
            }

            return <div>Please select a Class first</div>;
        };

        const getLearnerById = (classId, learnerId) => {
            const classSelected = classList.filter(cl => cl.group.groupId === classId)[0].childRoles.learnerList;

            const learnerSelected = classSelected.filter(learner => learner.pawn.pawnId === learnerId);

            return learnerSelected[0];
        };

        return (
            <>
                <h1>Learner Checklist Data</h1>
                <Formik>
                    {({ values, setFieldValue }) => (
                        <Form>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}>
                                <div style={{ width: '50%' }}>
                                    <Field
                                        name="class"
                                        component={CccisdSelect}
                                        label="Classes"
                                        options={[
                                            {
                                                value: 0,
                                                label: '-- Please select a Class --',
                                            },
                                            ...classOptions,
                                        ]}
                                        onChange={event => {
                                            const classId = event.target.value;
                                            setFieldValue('class', +classId);
                                            setFieldValue('learner', 0);
                                        }}
                                    />
                                </div>
                                {values.class && (
                                    <div style={{ width: '50%' }}>
                                        <Field
                                            name="learner"
                                            component={CccisdSelect}
                                            label="Participants"
                                            options={[
                                                {
                                                    value: 0,
                                                    label: '-- Please select a Learner --',
                                                },
                                                ...getLearnerOptions(classOptions, values.class),
                                            ]}
                                            onChange={event => {
                                                const learner = event.target.value;
                                                setFieldValue('learner', +learner);
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                            <div style={{ paddingRight: '1em' }}>
                                {values.class && values.learner !== 0 && (
                                    <DataTable selectedLearner={getLearnerById(values.class, values.learner)} />
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            </>
        );
    }

    if ([getLearnersQuery.isError].some(x => x === true)) {
        let ErrorLayout = errorLayout;

        return (
            <ErrorLayout>
                <NotFound />
            </ErrorLayout>
        );
    }
};

export default LearnerChecklistData;
