import React, { useEffect, useState } from 'react';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page } from 'cccisd-laravel-report';
import Loader from 'cccisd-loader';
import { NotFound } from 'cccisd-laravel-boilerplate';
import _findLastKey from 'lodash/findLastKey';

import { useGetLearnerChecklistsQuery } from '../../../../../queries/index.js';
import prepareData from './prepareData.js';
import style from './style.css';
import ReportHeader from '../../common/components/ReportHeader/index.js';
import TrackerTable from './TrackerTable/index.js';
import Alert from '../../../../../components/Alert/index.js';

import IconWarning from 'cccisd-icons/notification2';
import IconCheckCircle from 'cccisd-icons/checkmark-circle';

const Fortress = window.cccisd && window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;

const Component = () => {
    const [currentPart, setCurrentPart] = useState(0);
    let state = window.cccisd.store.getState();

    let currentFlowId = state.flow.player.flowId;

    useEffect(() => {
        setCurrentPart(currentFlowId);
    }, []);

    const getChecklists = useGetLearnerChecklistsQuery('learner', pawnId);

    let errorLayout = null;

    const isLoading = getChecklists.isLoading;
    const allQueryDataReturned = getChecklists.data;

    if (isLoading) {
        return <Loader loading={isLoading} />;
    }

    if (allQueryDataReturned) {
        const completedDays = allQueryDataReturned.data.roles.anyRole.checklists;
        const retroCompletedDates = allQueryDataReturned.data.flows;

        if (completedDays.length < 7) {
            return <Alert type="info" icon="info" text="Please wait for your report to generate." />;
        }
        const preparedData = prepareData(completedDays, retroCompletedDates, currentPart);

        const { asthmaControlTableRows, controllerTableRows, triggerTableRows, weeks } = preparedData;

        let {
            weeks: {
                weekBeforePart2,
                weekBeforePart3,
                weekBeforePart4,
                weekBeforePart5,
                weekBeforePart6,
                weekBeforePart7,
            },
        } = preparedData;
        const findActiveWeek = _findLastKey(weeks, o => o !== false);
        const activeWeek = weeks[findActiveWeek];

        const columns = [
            { name: 'id', label: '', width: '20%' },
            {
                name: 'part2',
                label: weekBeforePart2 ? weekBeforePart2.reportDateRange : 'Week Before 2',
                class: 'text-center',
                width: '13%',
            },
            {
                name: 'part3',
                label: weekBeforePart3 ? weekBeforePart3.reportDateRange : 'Week Before Part 3',
                class: 'text-center',
                width: '13%',
            },
            {
                name: 'part4',
                label: weekBeforePart4 ? weekBeforePart4.reportDateRange : 'Week Before Part 4',
                class: 'text-center',
                width: '13%',
            },
            {
                name: 'part5',
                label: weekBeforePart5 ? weekBeforePart5.reportDateRange : 'Week Before Part 5',
                class: 'text-center',
                width: '13%',
            },
            {
                name: 'part6',
                label: weekBeforePart6 ? weekBeforePart6.reportDateRange : 'Week Before Part 6',
                class: 'text-center',
                width: '13%',
            },
            {
                name: 'part7',
                label: weekBeforePart7 ? weekBeforePart7.reportDateRange : 'Week Before Part 7',
                class: 'text-center',
                width: '13%',
            },
        ];

        return (
            <Report>
                <div className={style.reportBody}>
                    <Page>
                        <ReportHeader title="My Checklist" allCaps="REPORT" />
                        <div className={style.pageBody}>
                            <div style={{ textAlign: 'center', marginTop: '2em' }}>
                                This report shows your checklist information for the past <strong>1 week(s)</strong>.
                            </div>
                            {activeWeek.warningTotals.length > 0 ? (
                                <>
                                    <div className={style.topBox}>
                                        <div className={style.icon} style={{ color: '#a94442' }}>
                                            <IconWarning />
                                        </div>
                                        <div>
                                            The week of <strong>{activeWeek.reportDateRange}</strong>, you reported the
                                            following:
                                            <strong>
                                                {activeWeek.warningTotals.map(warning => (
                                                    <div key={warning.id} className={style.warning}>
                                                        - {warning.text} {warning.daysExperienced} times in{' '}
                                                        {warning.totalDays} days
                                                    </div>
                                                ))}
                                            </strong>{' '}
                                            <div>
                                                These are{' '}
                                                <strong>warning signs that your asthma is not well controlled</strong>{' '}
                                                and you should see your healthcare provider.
                                            </div>
                                            <div style={{ marginTop: '1em' }}>
                                                Things you learn in today&apos;s part can help you get your asthma under
                                                control.
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        gap: '1em',
                                        border: '2px solid #017979',
                                        color: '#017979',
                                        padding: '1em',
                                        marginTop: '2em',
                                        marginRight: '-1em',
                                        marginLeft: '-1em',
                                    }}
                                >
                                    <div className={style.icon} style={{ color: '#017979' }}>
                                        <IconCheckCircle />
                                    </div>
                                    <div>
                                        During the week of <strong>{activeWeek.reportDateRange}</strong>, you reported
                                        your asthma was <strong>well controlled</strong>.
                                        <div style={{ marginTop: '1em', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                            Good job! Things you learn in today&apos;s part can help you keep your
                                            asthma under control.
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className={style.dates}>
                                <h4>ASTHMA CONTROL</h4>
                                <div>
                                    <span style={{ color: '#017979' }}>MY CURRENT WEEKLY CHECKLIST |</span>
                                    {` ${activeWeek.reportDateRange}`}
                                </div>
                            </div>
                            <div>
                                The picture below shows the number of days this week you used your long-term controller.
                            </div>
                            <div className={style.itemContainer}>
                                <div className={style.itemLabel}>
                                    <strong className={style.item}>{activeWeek.controller.id}</strong>
                                </div>
                                <div className={style.lineChart}>
                                    <ul className={style.lineChartContainer}>
                                        <li
                                            style={{
                                                background: activeWeek.controller.daysExperiencedBackground,
                                                flexBasis: activeWeek.controller.daysExperiencedFlexBasis,
                                            }}
                                            className={style.line}
                                        >
                                            <strong>{activeWeek.controller.daysExperienced}</strong>
                                        </li>
                                        <li
                                            className={style.line}
                                            style={{
                                                background: activeWeek.controller.noDaysBackground,
                                                flexBasis: activeWeek.controller.noDaysFlexBasis,
                                            }}
                                        />
                                    </ul>
                                </div>
                            </div>
                            <div>
                                The picture below shows the number of days this week that you had symptoms, the number
                                of nights you woke up because of your symptoms, the number of days you had to stop an
                                activity, and the number of days you had to use a quick reliever because of your
                                symptoms.
                            </div>
                            <div className={style.itemContainer}>
                                <div className={style.itemLabel}>
                                    <strong>{activeWeek.symptoms.id}</strong>
                                </div>
                                <div className={style.lineChart}>
                                    <ul className={style.lineChartContainer}>
                                        <li
                                            style={{
                                                background: activeWeek.symptoms.daysExperiencedBackground,
                                                flexBasis: activeWeek.symptoms.daysExperiencedFlexBasis,
                                            }}
                                            className={style.line}
                                        >
                                            <strong>{activeWeek.symptoms.daysExperienced}</strong>
                                        </li>
                                        <li
                                            className={style.line}
                                            style={{
                                                background: activeWeek.symptoms.noDaysBackground,
                                                flexBasis: activeWeek.symptoms.noDaysFlexBasis,
                                            }}
                                        />
                                    </ul>
                                </div>
                            </div>
                            <div className={style.itemContainer}>
                                <div className={style.itemLabel}>
                                    <strong>{activeWeek.nights.id}</strong>
                                </div>
                                <div className={style.lineChart}>
                                    <ul className={style.lineChartContainer}>
                                        <li
                                            style={{
                                                background: activeWeek.nights.daysExperiencedBackground,
                                                flexBasis: activeWeek.nights.daysExperiencedFlexBasis,
                                            }}
                                            className={style.line}
                                        >
                                            <strong>{activeWeek.nights.daysExperienced}</strong>
                                        </li>
                                        <li
                                            className={style.line}
                                            style={{
                                                background: activeWeek.nights.noDaysBackground,
                                                flexBasis: activeWeek.nights.noDaysFlexBasis,
                                            }}
                                        />
                                    </ul>
                                </div>
                            </div>
                            <div className={style.itemContainer}>
                                <div className={style.itemLabel}>
                                    <strong>{activeWeek.activities.id}</strong>
                                </div>
                                <div className={style.lineChart}>
                                    <ul className={style.lineChartContainer}>
                                        <li
                                            style={{
                                                background: activeWeek.activities.daysExperiencedBackground,
                                                flexBasis: activeWeek.activities.daysExperiencedFlexBasis,
                                            }}
                                            className={style.line}
                                        >
                                            <strong>{activeWeek.activities.daysExperienced}</strong>
                                        </li>
                                        <li
                                            className={style.line}
                                            style={{
                                                background: activeWeek.activities.noDaysBackground,
                                                flexBasis: activeWeek.activities.noDaysFlexBasis,
                                            }}
                                        />
                                    </ul>
                                </div>
                            </div>
                            <div className={style.itemContainer}>
                                <div className={style.itemLabel}>
                                    <strong>{activeWeek.reliever.id}</strong>
                                </div>
                                <div className={style.lineChart}>
                                    <ul className={style.lineChartContainer}>
                                        <li
                                            style={{
                                                background: activeWeek.reliever.daysExperiencedBackground,
                                                flexBasis: activeWeek.reliever.daysExperiencedFlexBasis,
                                            }}
                                            className={style.line}
                                        >
                                            <strong>{activeWeek.reliever.daysExperienced}</strong>
                                        </li>
                                        <li
                                            className={style.line}
                                            style={{
                                                background: activeWeek.reliever.noDaysBackground,
                                                flexBasis: activeWeek.reliever.noDaysFlexBasis,
                                            }}
                                        />
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Page>
                    <Page>
                        <ReportHeader title="My Checklist" allCaps="REPORT" />
                        <div style={{ marginTop: '1em' }}>
                            <TrackerTable
                                columns={columns}
                                data={controllerTableRows}
                                title="MEDICINE TO CONTROL MY ASTHMA"
                                subtitle={
                                    <div>
                                        This table shows your long-term controller checklist information from the past 1
                                        week(s)
                                    </div>
                                }
                                moreInfo={
                                    <div>
                                        <strong>How do things look for you?</strong> If you&apos;re supposed to be
                                        taking a long-term controller, <strong>you should take it every day</strong>,
                                        even when you don&apos;t have asthma symptoms.
                                    </div>
                                }
                            />
                            <div style={{ marginTop: '2em' }}>
                                <TrackerTable
                                    columns={columns}
                                    data={asthmaControlTableRows}
                                    title="MY ASTHMA CONTROL"
                                    subtitle={
                                        <div>This table shows the information you reported on your checklist</div>
                                    }
                                    moreInfo={
                                        <div>
                                            <strong>How do things look for you?</strong> If your asthma control is
                                            getting better, these numbers should be going down every week. Taking your
                                            long-term controller every day should decrease your symptoms, which means
                                            you won’t need to use your quick reliever as often.
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                    </Page>
                    <Page>
                        <ReportHeader title="My Checklist" allCaps="REPORT" />
                        <div style={{ marginTop: '1em' }}>
                            <TrackerTable
                                columns={columns}
                                data={triggerTableRows}
                                title="TRIGGERS"
                                subtitle={
                                    <div>
                                        This table shows the number of days each week you were around triggers, had
                                        asthma symptoms, and used a long-term controller. This table can be hard to
                                        figure out, because there may be a lot going on, but take a look at your
                                        numbers.
                                        <br />
                                        <br />
                                        <div>
                                            <strong>
                                                Do you notice any patterns? When you are around certain triggers, do you
                                                have more symptoms?
                                            </strong>
                                            Most teens need help figuring out this table. It&apos;s important to show
                                            this table to your provider so he or she can help you figure out how your
                                            triggers are related to your symptoms and long-term controller use.
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                    </Page>
                </div>
            </Report>
        );
    }

    if ([useGetLearnerChecklistsQuery.isError].some(x => x === true)) {
        let ErrorLayout = errorLayout;

        return (
            <ErrorLayout>
                <NotFound />
            </ErrorLayout>
        );
    }
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({})(Component);
