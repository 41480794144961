import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';
import { NotFound } from 'cccisd-laravel-boilerplate';

import { useGetModuleStartQuery } from '../../queries';
import Retrospective from '../Retrospective';
import { previous7days } from '../../pages/TwoDayChecklist/helpers';

const Fortress = window.cccisd && window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;

const SevenDayRetrospectiveWrapper = props => {
    const getModuleStart = useGetModuleStartQuery('moduleStart', pawnId);

    const { saveStateAndMetrics, field } = props;

    const isLoading = getModuleStart.isLoading;
    const allQueryDataReturned = getModuleStart.data;

    let errorLayout = null;

    if (isLoading) {
        return <Loader loading={isLoading} />;
    }

    if (allQueryDataReturned) {
        const allChecklists = allQueryDataReturned.data.roles.anyRole.checklists;

        const last7DaysChecklists = allChecklists.filter(value => previous7days.includes(value.date));

        if (last7DaysChecklists.length === 7) {
            if (field.value === '') {
                field.value = [{ content: { completeUponArrivalTo7Day: 7 } }];
            } else {
                field.value[0].content.completeUponArrivalTo7Day = 7;
            }
            saveStateAndMetrics();
        }

        return (
            <Retrospective
                days={7}
                saveStateAndMetrics={saveStateAndMetrics}
                field={field}
                completeUponArrivalTo7Day={last7DaysChecklists.length}
            />
        );
    }

    if ([useGetModuleStartQuery.isError].some(x => x === true)) {
        let ErrorLayout = errorLayout;

        return (
            <ErrorLayout>
                <NotFound />
            </ErrorLayout>
        );
    }
};

SevenDayRetrospectiveWrapper.propTypes = {
    saveStateAndMetrics: PropTypes.func,
    field: PropTypes.object,
};

export default SevenDayRetrospectiveWrapper;
