import React, { useState, useEffect } from 'react';
import Tooltip from 'cccisd-tooltip';
import PropTypes from 'prop-types';
import Modal from 'cccisd-modal';
import { Player } from 'cccisd-laravel-flow';

import style from '../style.css';
import { findIsland } from '../helpers.js';
import ChecklistReportPlayer from './ChecklistReportPlayer';
import { useGetLearnerChecklistsQuery } from '../../../queries/index.js';

import LockIcon from 'cccisd-icons/lock4';
import PlayIcon from 'cccisd-icons/play4';
import ReplayIcon from 'cccisd-icons/spinner11';

import surveyHeader from '../../../../images/symptomTracker/mobile_background.png';
import talkToParents from '../../../../images/reviewThumbnails/talk_to_parents.png';
import talkToProvider from '../../../../images/reviewThumbnails/talk_to_provider.png';
import actionPlan from '../../../../images/reviewThumbnails/action_plan.png';
import medicineReview from '../../../../images/reviewThumbnails/medicine_review.png';
import tips from '../../../../images/reviewThumbnails/tips.png';
import triggers from '../../../../images/reviewThumbnails/triggers.png';
import printableChecklist from '../../../../images/reviewThumbnails/printable_checklist.png';

const Fortress = window.cccisd && window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;
const userRole = Fortress.user.acting.role.handle;
const learner = userRole === 'learner';
const timeSkip = Fortress.user.acting.role_data.timeSkip === 1;
const height = '65px';
const width = '65px';

const LessonCard = props => {
    const [buttonSettings, setButtonSettings] = useState({
        text: 'BEGIN',
        icon: <PlayIcon />,
        color: 'primary',
    });
    const [blockProgress, setBlockProgress] = useState(false);

    const { backgroundColor, currentFlowProps, isNetworkError, getProgressQuery, lesson, module, tooltipSettings } =
        props;

    let { showReport, showResources } = props;

    // console.log('showReport early', showReport);
    // console.log('showResources early', showResources);

    const { dayDiff, completedModules, isNextModuleOpen, lastModule, nextModule } = tooltipSettings;

    useEffect(() => {
        if (!module.isAllowed) {
            return setButtonSettings({ text: 'LOCKED', icon: <LockIcon />, color: 'default' });
        }

        if (learner && !timeSkip && module.isAllowed && !module.isComplete) {
            if (dayDiff >= 7) {
                return;
            }
            if (!isNextModuleOpen && dayDiff < 7 && module.id !== 1) {
                setButtonSettings({ text: 'LOCKED', icon: <LockIcon />, color: 'default' });
                return setBlockProgress(true);
            }
        }
        if (module.isAllowed && !module.isComplete && module.isResume) {
            return setButtonSettings({ text: 'RESUME', icon: <PlayIcon />, color: 'danger' });
        }

        if (module.id === 1 && !module.isComplete) {
            return setButtonSettings(buttonSettings);
        }

        if (module.isComplete) {
            return setButtonSettings({ text: 'REPLAY', icon: <ReplayIcon />, color: 'primary' });
        }
    }, [dayDiff, lesson, completedModules]);

    const getChecklists = useGetLearnerChecklistsQuery('learner', pawnId);

    const isChecklistReportReady =
        module.id !== 1 && getChecklists?.data?.data?.flows[`part${module.id}retro`].entityState.completedFirstDate
            ? true
            : false;

    const tooltipText =
        module.isAllowed && learner && !timeSkip && nextModule === lesson.moduleId && dayDiff !== undefined
            ? `It has been ${dayDiff} days since you completed Part ${lastModule}. You can start Part ${nextModule} when 7 days have passed since you completed Part ${lastModule}.`
            : `Locked until previous parts are completed.`;

    const reviewResources = [
        {
            id: 1,
            label: 'Talking to Your Parents',
            icon: <img src={talkToParents} alt="thumbnail" height={height} width={width} />,
            href: module.pdfUrlTalkingParents,
            displayInModules: [3, 4, 5, 6, 7],
        },
        {
            id: 2,
            label: 'Talking to Your Provider',
            icon: <img src={talkToProvider} alt="thumbnail" height={height} width={width} />,
            href: module.pdfUrlTalkingProvider,
            displayInModules: [3, 4, 5, 6, 7],
        },
        {
            id: 3,
            label: 'Asthma Action Plan',
            icon: <img src={actionPlan} alt="thumbnail" height={height} width={width} />,
            href: module.pdfUrlActionPlan,
            displayInModules: [3, 4, 5, 6, 7],
        },
        {
            id: 4,
            label: 'Asthma Medicine Review',
            icon: <img src={medicineReview} alt="thumbnail" height={height} width={width} />,
            href: module.pdfUrlMedicineReview,
            displayInModules: [3, 4, 5, 6, 7],
        },
        {
            id: 5,
            label: 'Remembering to Fill Out Your Checklist',
            icon: <img src={tips} alt="thumbnail" height={height} width={width} />,
            href: module.pdfUrlRememberingChecklist,
            displayInModules: [5, 6, 7],
        },
        {
            id: 6,
            label: 'Understanding Triggers',
            icon: <img src={triggers} alt="thumbnail" height={height} width={width} />,
            href: module.pdfUrlUnderstandingTriggers,
            displayInModules: [6, 7],
        },
        {
            id: 7,
            label: 'Printable Checklist',
            icon: <img src={printableChecklist} alt="thumbnail" height={height} width={width} />,
            href: module.pdfUrlPrintableChecklist,
            displayInModules: [7],
        },
    ];

    const makeResourceList = () => {
        return reviewResources
            .filter(resource => resource.displayInModules.includes(completedModules))
            .map(resource => {
                return (
                    <li key={resource.id}>
                        <a href={resource.href} target="_blank" rel="noopener noreferrer" className={style.resource}>
                            {resource.icon}
                            <div>{resource.label}</div>
                        </a>
                    </li>
                );
            });
    };

    if (!timeSkip && learner && !isNextModuleOpen && dayDiff < 7 && module.id !== 1 && module.id > completedModules) {
        showReport = false;
        showResources = false;
    }

    if (module.id === 2 && !isChecklistReportReady) {
        showReport = false;
        showResources = false;
    }

    return (
        <div className={style.lessonCard}>
            <div className={style.moduleDetails}>
                <div
                    className={style.island}
                    style={{
                        backgroundImage: `url(${findIsland(module.id)})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        width: '100%',
                        height: '380px',
                    }}
                />
                <div className={style.player}>
                    <h5>PART {module.id}</h5>
                    <h4>{module.title}</h4>
                    {(module.isAllowed && !blockProgress) || module.id === 1 ? (
                        <Modal
                            trigger={
                                <button type="button" className={`btn btn-${buttonSettings.color}`}>
                                    <div className={style.actionButton}>
                                        <span>{buttonSettings.icon}</span>&nbsp;&nbsp;
                                        {buttonSettings.text}
                                    </div>
                                </button>
                            }
                            beforeShow={() => {
                                lesson.setCurrent();
                            }}
                            title={`Part ${module.id}: ${module.title}`}
                            size="large"
                            headerStyle={{
                                backgroundColor,
                                fontFamily: 'Raleway, sans-serif',
                                backgroundImage: `url(${surveyHeader})`,
                                backgroundSize: '20%',
                                backgroundRepeat: 'repeat',
                                color: 'black',
                            }}
                            bodyStyle={{
                                backgroundColor,
                                fontFamily: 'Raleway, sans-serif',
                            }}
                            afterClose={() => {
                                getProgressQuery();
                                getChecklists.refetch();
                            }}
                            render={({ closeModal }) => (
                                <Player
                                    key={lesson.handle}
                                    {...currentFlowProps}
                                    onComplete={async whatevs => {
                                        await currentFlowProps.onComplete(whatevs);
                                        closeModal();
                                    }}
                                    isNetworkError={isNetworkError}
                                />
                            )}
                        />
                    ) : (
                        <Tooltip title={tooltipText} placement="bottom">
                            <button
                                type="button"
                                className={`btn btn-${buttonSettings.color}`}
                                onClick={() => {}}
                                disabled
                            >
                                <div className={style.actionButton}>
                                    <span>{buttonSettings.icon}</span>&nbsp;&nbsp;
                                    {buttonSettings.text}
                                </div>
                            </button>
                        </Tooltip>
                    )}
                </div>
            </div>
            <div className={style.review}>
                {showResources && <h3>Quick Review:</h3>}
                <ul className={style.reviewResources}>
                    {showReport && (
                        <ChecklistReportPlayer
                            backgroundColor={backgroundColor}
                            currentFlowProps={currentFlowProps}
                            isChecklistReportReady={isChecklistReportReady}
                            isNetworkError={isNetworkError}
                            lesson={lesson}
                            module={module}
                            imageHeight={height}
                            imageWidth={width}
                        />
                    )}
                    {showResources && makeResourceList()}
                </ul>
            </div>
        </div>
    );
};

export default LessonCard;

LessonCard.propTypes = {
    backgroundColor: PropTypes.string,
    beforeClose: PropTypes.func,
    currentFlowProps: PropTypes.object,
    deploymentId: PropTypes.number,
    getProgressQuery: PropTypes.func,
    isNetworkError: PropTypes.bool,
    lesson: PropTypes.object,
    module: PropTypes.object,
    showReport: PropTypes.bool,
    showResources: PropTypes.bool,
    tooltipSettings: PropTypes.object,
};
