import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { format, subDays, parseISO } from 'date-fns';
import axios from 'cccisd-axios';
import Loader from 'cccisd-loader';
import { NotFound } from 'cccisd-laravel-boilerplate';
import Tooltip from 'cccisd-tooltip';

import SymptomTrackerForm from './SymptomTrackerForm';
import style from './style.css';
import { reportItems, previous7days, prev30days, triggerItems } from '../../pages/TwoDayChecklist/helpers';
import { useGetChecklists } from '../../queries';

import dayMissed from '../../../images/symptomTracker/missingDay.png';
import dayComplete from '../../../images/symptomTracker/completedDay.png';
import horizontalLogo from '../../../images/symptomTracker/horizontalLogo.png';
import phoneBgClear from '../../../images/symptomTracker/phone_bg_clear.png';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd && window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;
const pawnHash = Fortress.user.acting.random_hash;

const today = format(new Date(), 'yyyy-MM-dd');

const Retrospective = props => {
    const [currentHexagon, setCurrentHexagon] = useState(null);

    const getChecklists = useGetChecklists('checklist', pawnId, previous7days);

    const { days, field, completeUponArrivalTo7Day, saveStateAndMetrics } = props;

    const isLoading = getChecklists.isLoading;
    const allQueryDataReturned = getChecklists.data;

    /* There are 3 types of Retrospectives - 1 day, 2 days, and 7 days. The 1 and 7 days 
    Retrospectives save data in 2 spots onSubmit:
        1) posts to the route ('store.campair.checklist')
        2) saves data in redux using field and saveStateandMetrics
        See resources/assets/js/vendor/flow/components/campAir7Day/Player/index.js

     2 days Retrospective only posts to the route and doesn't have saveStateAndMetrics() 
     or field available to it because it's not used within the player wrapper.
     See resources/assets/js/pages/TwoDayChecklist/index.js
     
     So we have to sync up the data saved in both places to be the same. */
    useEffect(() => {
        allQueryDataReturned && field && field.onChange({ ...allQueryDataReturned.data.data });
    }, [allQueryDataReturned, field]);

    let errorLayout = null;
    let totalHexagons;

    const previous2days = [0, 1].map(i => {
        const timeStamp = subDays(new Date(), i);

        return format(timeStamp, 'yyyy-MM-dd');
    });

    if (isLoading) {
        return <Loader loading={isLoading} />;
    }

    const currentAnswer = field?.value || null;

    const openForm = id => {
        setCurrentHexagon(id);
    };

    const closeForm = () => {
        setCurrentHexagon(null);
    };

    const formatDayLabel = (dateString, type) => {
        const parseFirst = parseISO(dateString);
        return type === 'shorten' ? format(parseFirst, 'eeeeee') : format(parseFirst, 'eeee');
    };

    if (allQueryDataReturned) {
        const completedDays = allQueryDataReturned.data.data;

        const makeHexagons = previousDays => {
            // collect checklist data for the previous 7 days, if we have it
            const completes = completedDays.filter(value => previousDays.includes(value.date));

            const completedDates = completedDays.map(hex => {
                return hex.date;
            });

            // if we don't have checklist data for that date, we need to add a 'date' key
            const incompletes = previousDays
                .filter(date => !completedDates.includes(date))
                .map(el => {
                    return { date: el };
                });

            // merge the 2 arrays and sort chronologically
            return [...completes, ...incompletes].sort((a, b) => new Date(a.date) - new Date(b.date));
        };

        if (days === 7) {
            totalHexagons = makeHexagons(previous7days);
        }

        if (days === 2) {
            totalHexagons = makeHexagons(previous2days);
        }

        if (days === 1) {
            totalHexagons = makeHexagons([today]);
        }

        const thirtyDays = makeHexagons(prev30days).filter(hex => hex.content);

        const getInitialValues = date => {
            const initValues = totalHexagons.filter(hex => hex.date === date);

            return initValues.length === 1 ? initValues[0].content : null;
        };

        const renderAlertText = () => {
            const last7dayCompletes = makeHexagons(previous7days).filter(hex => hex.content);

            const completeCount = (
                <div>
                    Completed <strong>{last7dayCompletes.length} of last 7</strong> days.
                </div>
            );

            if (days === 1) {
                return (
                    <>
                        <div>Select the yellow icon to complete today&apos;s checklist.</div>
                    </>
                );
            }

            if (days === 7 && last7dayCompletes.length !== 7) {
                return (
                    <>
                        <div>
                            It looks like you weren&apos;t able to completely fill out your checklist every day. Please
                            do that now.
                        </div>
                        <div>{completeCount}</div>
                    </>
                );
            }
            return <div>{completeCount}</div>;
        };

        const totals = (value, dayTotal) => {
            const sevenDayTotal = makeHexagons(previous7days).filter(hex => hex.content);

            return dayTotal === 'seven'
                ? sevenDayTotal.filter(day => day.content[value] === 'Yes').length
                : thirtyDays.filter(day => day.content[value] === 'Yes').length;
        };

        const sevenDayTotals = reportItems.reduce((obj, item) => {
            obj[item] = totals(item, 'seven');
            return obj;
        }, {});

        const thirtyDayTotals = reportItems.reduce((obj, item) => {
            obj[item] = totals(item, 'thirty');
            return obj;
        }, {});

        const triggersMoreThanZeroInThirtyDays = triggerItems.reduce((obj, item) => {
            if (totals(item, 'thirty') > 0) {
                obj[item] = totals(item, 'thirty');
            }
            return obj;
        }, {});

        const useStyleContainer = () => {
            if (days === 7) {
                return style.sevenDayContainer;
            }

            if (days === 2) {
                return style.twoDayContainer;
            }

            return style.oneDayContainer;
        };

        const onSubmit = async values => {
            const toPost = {
                pawnHash,
                pawnId,
                date: values.day,
                content: {
                    ...values,
                    triggersMoreThanZeroInThirtyDays,
                    completeUponArrivalTo7Day,
                    dateComplete: new Date(),
                },
            };

            const response = await axios.post(Boilerplate.route('store.campair.checklist'), toPost);

            if (response.data.errors) {
                return Promise.reject(response.data.errors);
            }

            getChecklists.refetch();

            if (days === 7 || days === 1) {
                await field.onChange({
                    ...currentAnswer,
                    [values.day]: values,
                });

                await saveStateAndMetrics();

                closeForm();
            }
        };

        return (
            <div className={days === 2 ? style.twoDayBody : ''}>
                <div className={style.body}>
                    {!currentHexagon && days === 1 && (
                        <div className={style.oneDayPrompt}>
                            <img
                                src={phoneBgClear}
                                className={style.iPhone}
                                alt="iphone background"
                                width="890px"
                                height="530px"
                            />
                            <div className={style.phoneFace}>
                                <div className={style.logo}>
                                    <img src={horizontalLogo} alt="alternate Camp Air Logo" width="285px" />
                                </div>
                                <div className={style.banner} role="alert">
                                    {renderAlertText()}
                                </div>
                            </div>
                        </div>
                    )}
                    {!currentHexagon && days === 2 && (
                        <>
                            <div className={style.logo}>
                                <img src={horizontalLogo} alt="alternate Camp Air Logo" width="285px" />
                            </div>
                            <div className={style.banner} role="alert">
                                {renderAlertText()}
                            </div>
                        </>
                    )}
                    {!currentHexagon && days === 7 && (
                        <div className={style.banner} role="alert">
                            {renderAlertText()}
                        </div>
                    )}
                    {!currentHexagon && (
                        <div className={useStyleContainer()}>
                            {totalHexagons.map(day => (
                                <div key={day.date}>
                                    <a onClick={() => openForm(day.date)} style={{ textDecoration: 'none' }}>
                                        <div className={style.checklist}>
                                            <div className={style.badge}>
                                                <img
                                                    src={day.content ? dayComplete : dayMissed}
                                                    alt="day complete badge"
                                                    height="100vh"
                                                />
                                                <div className={style.dayOfWeek}>
                                                    <Tooltip title={formatDayLabel(day.date, 'full')}>
                                                        <strong>{formatDayLabel(day.date, 'shorten')}</strong>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <div className={style.helperText}>
                                                {day.content ? 'is complete' : 'needs to be completed'}
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    )}
                    {currentHexagon && days === 1 && (
                        <div className={style.oneDayPrompt}>
                            <div>
                                <img
                                    src={phoneBgClear}
                                    className={style.iPhone}
                                    alt="iphone background"
                                    width="890px"
                                    height="530px"
                                />
                                <div className={style.phoneFace}>
                                    <SymptomTrackerForm
                                        closeForm={closeForm}
                                        day={currentHexagon}
                                        days={days}
                                        field={field}
                                        initialValues={getInitialValues(currentHexagon)}
                                        onSubmit={onSubmit}
                                        sevenDayTotals={sevenDayTotals}
                                        thirtyDayTotals={thirtyDayTotals}
                                    />
                                </div>
                            </div>
                            <div className={style.oneDayInstructions}>
                                <ul>
                                    <li>
                                        Select the arrow on each row to see the full question or select Expand All to
                                        see all of the questions at once.
                                    </li>
                                    <li>Answer YES or NO for each question.</li>
                                    <li>
                                        For the medicine questions, you can select IDK if you&apos;re not sure what kind
                                        of medicine you&apos;re taking.
                                    </li>
                                </ul>
                                The Environment question asks about triggers. If you haven&apos;t been around any
                                triggers, select NO. If you have been around triggers, select YES and then choose each
                                trigger you&apos;ve been around by selecting the YES button that appears next to that
                                trigger. You don&apos;t need to select anything for triggers you haven&apos;t been
                                around.
                                <br />
                                <br />
                                Select the DONE button once you&apos;ve answered all of the questions.
                            </div>
                        </div>
                    )}
                    {currentHexagon && days === 2 && (
                        <div className={style.twoDayPrompt}>
                            <div className={style.phoneFace}>
                                <SymptomTrackerForm
                                    closeForm={closeForm}
                                    day={currentHexagon}
                                    days={days}
                                    field={field}
                                    initialValues={getInitialValues(currentHexagon)}
                                    onSubmit={onSubmit}
                                    sevenDayTotals={sevenDayTotals}
                                    thirtyDayTotals={thirtyDayTotals}
                                />
                            </div>
                        </div>
                    )}
                    {currentHexagon && days === 7 && (
                        <div className={style.sevenDayPrompt}>
                            <div className={style.phone}>
                                <img
                                    src={phoneBgClear}
                                    className={style.iPhone}
                                    alt="iphone background"
                                    width="890px"
                                    height="530px"
                                />
                                <div className={style.phoneFace}>
                                    <SymptomTrackerForm
                                        closeForm={closeForm}
                                        day={currentHexagon}
                                        days={days}
                                        field={field}
                                        initialValues={getInitialValues(currentHexagon)}
                                        onSubmit={onSubmit}
                                        sevenDayTotals={sevenDayTotals}
                                        thirtyDayTotals={thirtyDayTotals}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    if ([useGetChecklists.isError].some(x => x === true)) {
        let ErrorLayout = errorLayout;

        return (
            <ErrorLayout>
                <NotFound />
            </ErrorLayout>
        );
    }
};

export default Retrospective;

Retrospective.propTypes = {
    days: PropTypes.number,
    field: PropTypes.object,
    completeUponArrivalTo7Day: PropTypes.number,
    saveStateAndMetrics: PropTypes.func,
};
