import React from 'react';
import { Field, CccisdFieldWrapper } from 'cccisd-formik';
import PropTypes from 'prop-types';

const ResponseButton = props => {
    const { disabled, value, name, handleClick, threeBtns } = props;

    return (
        <Field name={`${value} ${name}`} enableReinitialize>
            {({ field, form }) => {
                return (
                    <CccisdFieldWrapper field={field} form={form}>
                        <button
                            style={
                                threeBtns
                                    ? { width: '50px', lineHeight: '1em', borderRadius: '0px' }
                                    : { width: '80px', lineHeight: '1em', borderRadius: '0px' }
                            }
                            type="button"
                            className={form.values[name] === value ? 'btn btn-danger btn-sm' : 'btn btn-info btn-sm'}
                            value={field.name}
                            onClick={() => {
                                handleClick(`${name}`, value);
                            }}
                            disabled={disabled}
                        >
                            {value}
                        </button>
                    </CccisdFieldWrapper>
                );
            }}
        </Field>
    );
};

export default ResponseButton;

ResponseButton.propTypes = {
    disabled: PropTypes.bool,
    handleClick: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.string,
    threeBtns: PropTypes.bool,
};
