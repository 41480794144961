import React from 'react';
import PropTypes from 'prop-types';

import circleLogo from '../../../../../../../images/reportImages/circleLogo.png';
import style from './style.css';

const ReportHeader = props => {
    const { title, allCaps } = props;

    return (
        <div>
            <div className={style.background}>
                <div className={style.logo}>
                    <img src={circleLogo} alt="circle Camp Air logo" height="125px" width="125px" />
                </div>
                <div className={style.title}>
                    <h2 style={{ fontWeight: '400' }}>
                        {title} <strong style={{ fontWeight: '700' }}>{allCaps}</strong>
                    </h2>
                </div>
            </div>
        </div>
    );
};

export default ReportHeader;

ReportHeader.propTypes = {
    title: PropTypes.string,
    allCaps: PropTypes.string,
};
