import React from 'react';
import Modal from 'cccisd-modal';
import { Player } from 'cccisd-laravel-flow';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';
import { NotFound } from 'cccisd-laravel-boilerplate';

import checklistReport from '../../../../../images/reviewThumbnails/checklist_report.png';
import grayChecklistReport from '../../../../../images/reviewThumbnails/gray_checklist_report.png';
import surveyHeader from '../../../../../images/symptomTracker/mobile_background.png';
import { useGetChecklistReportQuery } from '../../../../queries';
import style from '../../style.css';

const ChecklistReportPlayer = props => {
    const {
        backgroundColor,
        currentFlowProps,
        imageHeight,
        imageWidth,
        isChecklistReportReady,
        isNetworkError,
        lesson,
        module,
    } = props;

    const getChecklistReportQuery = useGetChecklistReportQuery('checklistReport', lesson.handle);

    let errorLayout = null;

    const isLoading = getChecklistReportQuery.isLoading;
    const allQueryDataReturned = getChecklistReportQuery.data;

    if (isLoading) {
        return <Loader loading={isLoading} removeChildren={!isNetworkError} />;
    }

    if (allQueryDataReturned) {
        const flowComponentId = allQueryDataReturned.data.flows.assignmentProgress.surveylist.flow.entity.entityId;

        return (
            <Modal
                trigger={
                    module.isComplete || isChecklistReportReady ? (
                        <a className={style.resource}>
                            <img
                                src={checklistReport}
                                alt="checklist report thumbnail"
                                height={imageHeight}
                                width={imageWidth}
                            />
                            Checklist Report
                        </a>
                    ) : (
                        <a className={style.resource} style={{ cursor: 'not-allowed', color: '#ccc' }}>
                            <img
                                src={grayChecklistReport}
                                alt="checklist report thumbnail"
                                height={imageHeight}
                                width={imageWidth}
                            />
                            Checklist Report
                        </a>
                    )
                }
                beforeShow={() => {
                    lesson.setCurrent();
                }}
                title={`Part ${module.id}: ${module.title}`}
                size="large"
                headerStyle={{
                    backgroundColor,
                    fontFamily: 'Raleway, sans-serif',
                    backgroundImage: `url(${surveyHeader})`,
                    backgroundSize: '20%',
                    backgroundRepeat: 'repeat',
                }}
                bodyStyle={{
                    backgroundColor,
                    fontFamily: 'Raleway, sans-serif',
                }}
            >
                <Player
                    key={lesson.handle}
                    {...currentFlowProps}
                    isNetworkError={isNetworkError}
                    pageFirstElementId={flowComponentId}
                />
            </Modal>
        );
    }

    if ([getChecklistReportQuery.isError].some(x => x === true) || isNetworkError) {
        let ErrorLayout = errorLayout;

        return (
            <ErrorLayout>
                <NotFound />
            </ErrorLayout>
        );
    }
};

export default ChecklistReportPlayer;

ChecklistReportPlayer.propTypes = {
    backgroundColor: PropTypes.string,
    beforeClose: PropTypes.func,
    currentFlowProps: PropTypes.object,
    closeFlowPlayer: PropTypes.func,
    deploymentId: PropTypes.number,
    imageHeight: PropTypes.string,
    imageWidth: PropTypes.string,
    isChecklistReportReady: PropTypes.bool,
    isNetworkError: PropTypes.bool,
    lesson: PropTypes.object,
    module: PropTypes.object,
};
