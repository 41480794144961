import React, { useState } from 'react';
import classnames from 'classnames';
import _xor from 'lodash/xor';
import { Formik } from 'cccisd-formik';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';

import style from './style.css';
import { items, triggers, generateInitialValues } from '../../../pages/TwoDayChecklist/helpers.js';
import ResponseButton from './ResponseButton';
import WarningScreen from './Warning';
import Retrospective from '..';

import IconArrowRight from 'cccisd-icons/arrow-right';
import IconArrowLeft from 'cccisd-icons/arrow-left';
import IconCheckCircle from 'cccisd-icons/checkmark-circle';
import IconNotification from 'cccisd-icons/notification';

const SymptomTrackerForm = props => {
    const [expandedCards, setExpandedCards] = useState([]);
    const [showWarning, setShowWarning] = useState(false);
    const [showRetrospective, setShowRetrospective] = useState(false);

    const { closeForm, day, days, initialValues, onSubmit, sevenDayTotals, thirtyDayTotals } = props;

    const toggleCardExpanded = id => {
        setExpandedCards(_xor(expandedCards, [id]));
    };

    const mainItems = items.filter(item => item.class === 'mainItem');

    const onHandleSubmit = async values => {
        values.day = day;

        await onSubmit(values);

        if (days === 1 || days === 7) {
            setShowRetrospective(true);
        } else {
            setShowWarning(true);
        }
    };

    const expandAllCards = () => {
        const allItems = [...items, ...triggers].map(item => {
            return item.id;
        });

        allItems.length === expandedCards.length ? setExpandedCards([]) : setExpandedCards(allItems);
    };

    const validate = values => {
        const errors = {};

        items
            .map(item => {
                return item.name;
            })
            .map(name => {
                if (!values[name]) {
                    errors[`Yes ${name}`] = 'Required';
                }
                return null;
            });

        return errors;
    };

    const parseAndFormatDate = dateString => {
        const parseFirst = parseISO(dateString);

        return format(parseFirst, 'EEEE LLLL do');
    };

    return (
        <div className={style.form} style={{ backgroundColor: '#f0e9dc', fontSize: days === 2 ? '11px' : '16px' }}>
            {!showWarning && !showRetrospective && (
                <Formik
                    onSubmit={e => onHandleSubmit(e)}
                    validate={e => validate(e)}
                    initialValues={initialValues ? initialValues : generateInitialValues()}
                >
                    {({ handleSubmit, values, errors, setFieldValue, isSubmitting, submitCount, isValid }) => (
                        <div>
                            <div className={style.formHeader}>
                                <a
                                    onClick={() => {
                                        setShowRetrospective(true);
                                        closeForm();
                                    }}
                                >
                                    <IconArrowLeft />
                                </a>
                                <div className={style.formDate}>{parseAndFormatDate(day)}</div>
                            </div>
                            <button
                                type="button"
                                onClick={() => expandAllCards()}
                                className={`btn btn-primary ${style.actionButton}`}
                            >
                                {expandedCards.length > 0 ? 'Collapse All' : 'Expand All'}
                            </button>
                            <div className="row">
                                <div className="col-xs-12">
                                    {mainItems.map(item => {
                                        return (
                                            <div
                                                key={item.id}
                                                className={classnames(style.item, {
                                                    [style.expanded]: expandedCards.includes(item.id),
                                                })}
                                            >
                                                {item.class === 'mainItem' && item.name && (
                                                    <div
                                                        className={classnames(style.name, {
                                                            [style.expanded]: expandedCards.includes(item.id),
                                                        })}
                                                    >
                                                        <div
                                                            className={style.label}
                                                            onClick={() => toggleCardExpanded(item.id)}
                                                        >
                                                            <div className={style.thumbnail}>{item.img}</div>
                                                            <div className={style.symptomName}>{item.name}</div>
                                                            <div
                                                                className={classnames(style.arrow, {
                                                                    [style.expanded]: expandedCards.includes(item.id),
                                                                })}
                                                            >
                                                                <IconArrowRight />
                                                            </div>
                                                        </div>
                                                        <div className={style.symptomButton}>
                                                            {item.btns === 2 ? (
                                                                <>
                                                                    <ResponseButton
                                                                        value="Yes"
                                                                        name={item.name}
                                                                        handleClick={() =>
                                                                            setFieldValue(item.name, 'Yes')
                                                                        }
                                                                        disabled={initialValues && days === 7 && true}
                                                                    />
                                                                    <ResponseButton
                                                                        value="No"
                                                                        name={item.name}
                                                                        handleClick={() =>
                                                                            setFieldValue(item.name, 'No')
                                                                        }
                                                                        disabled={initialValues && days === 7 && true}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <ResponseButton
                                                                        value="Yes"
                                                                        name={item.name}
                                                                        handleClick={() =>
                                                                            setFieldValue(item.name, 'Yes')
                                                                        }
                                                                        disabled={initialValues && days === 7 && true}
                                                                        threeBtns
                                                                    />
                                                                    <ResponseButton
                                                                        value="IDK"
                                                                        name={item.name}
                                                                        handleClick={() =>
                                                                            setFieldValue(item.name, 'IDK')
                                                                        }
                                                                        disabled={initialValues && days === 7 && true}
                                                                        threeBtns
                                                                    />
                                                                    <ResponseButton
                                                                        value="No"
                                                                        name={item.name}
                                                                        handleClick={() =>
                                                                            setFieldValue(item.name, 'No')
                                                                        }
                                                                        disabled={initialValues && days === 7 && true}
                                                                        threeBtns
                                                                    />
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                                {expandedCards.includes(item.id) && (
                                                    <div className={style.inner}>{item.inner}</div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            {values.Environment === 'Yes' &&
                                triggers.map(trigger => {
                                    return (
                                        <div
                                            key={trigger.id}
                                            className={classnames(style.item, {
                                                [style.expanded]: expandedCards.includes(trigger.id),
                                            })}
                                        >
                                            <div
                                                className={classnames(style.name, {
                                                    [style.expanded]: expandedCards.includes(trigger.id),
                                                })}
                                            >
                                                <div
                                                    className={style.label}
                                                    onClick={() => toggleCardExpanded(trigger.id)}
                                                    style={{ marginLeft: '0.5em' }}
                                                >
                                                    {trigger.img}
                                                    <div className={style.symptomName}>{trigger.name}</div>
                                                    <div
                                                        className={classnames(style.arrow, {
                                                            [style.expanded]: expandedCards.includes(trigger.id),
                                                        })}
                                                    >
                                                        <IconArrowRight />
                                                    </div>
                                                </div>
                                                <div
                                                    className={style.triggerButtons}
                                                    style={
                                                        submitCount > 0 && errors[`No ${trigger.name}`]
                                                            ? { border: '2px solid #a94442' }
                                                            : {}
                                                    }
                                                >
                                                    <ResponseButton
                                                        value="Yes"
                                                        name={trigger.name}
                                                        handleClick={() => setFieldValue(trigger.name, 'Yes')}
                                                        disabled={initialValues && days === 7 && true}
                                                    />
                                                    <ResponseButton
                                                        value="No"
                                                        name={trigger.name}
                                                        handleClick={() => setFieldValue(trigger.name, 'No')}
                                                        disabled={initialValues && days === 7 && true}
                                                    />
                                                </div>
                                            </div>
                                            {expandedCards.includes(trigger.id) && (
                                                <div className={style.inner}>{trigger.inner}</div>
                                            )}
                                        </div>
                                    );
                                })}
                            {submitCount > 0 && !isValid && !isSubmitting && (
                                <div className={style.errorAlert} role="alert">
                                    <IconNotification spaceRight />
                                    <strong>Please select a response for each item.</strong>
                                </div>
                            )}
                            <div className={style.doneButton}>
                                <button
                                    type="button"
                                    onClick={() => handleSubmit()}
                                    className={`btn btn-primary ${style.actionButton}`}
                                >
                                    <IconCheckCircle spaceRight />
                                    Done
                                </button>
                            </div>
                        </div>
                    )}
                </Formik>
            )}
            {showWarning && days === 2 && (
                <WarningScreen
                    sevenDayTotals={sevenDayTotals}
                    thirtyDayTotals={thirtyDayTotals}
                    closeForm={closeForm}
                />
            )}
            {showRetrospective && <Retrospective days={days} />}
        </div>
    );
};

export default SymptomTrackerForm;

SymptomTrackerForm.propTypes = {
    closeForm: PropTypes.func,
    day: PropTypes.string,
    days: PropTypes.number,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
    previous7days: PropTypes.array,
    sevenDayTotals: PropTypes.object,
    thirtyDayTotals: PropTypes.object,
};
