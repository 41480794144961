import React from 'react';
import PropTypes from 'prop-types';
import { playerWrapper } from 'cccisd-survey-common';

import SevenDayRetrospectiveWrapper from '../../../../../components/SevenDayRetrospectiveWrapper';

class Player extends React.Component {
    static propTypes = {
        field: PropTypes.object,
        saveStateAndMetrics: PropTypes.func,
        isPreview: PropTypes.bool,
    };

    render() {
        const { field, isPreview, saveStateAndMetrics } = this.props;

        if (isPreview) {
            return (
                <div>
                    7 Day Checklist <h5>Please add a page break after me.</h5>
                </div>
            );
        }

        return <SevenDayRetrospectiveWrapper saveStateAndMetrics={saveStateAndMetrics} field={field} />;
    }
}

export default playerWrapper()(Player);
