import React from 'react';
import PropTypes from 'prop-types';

import IconInfo from 'cccisd-icons/info';
import IconWarning from 'cccisd-icons/warning';

const Alert = props => {
    const { icon, text, type } = props;

    const renderIcon = () => {
        switch (icon) {
            case 'warning':
                return <IconWarning spaceRight />;
            case 'info':
                return <IconInfo spaceRight />;
            default:
                return <IconInfo spaceRight />;
        }
    };

    return (
        <div className={`alert alert-${type}`} style={{ textAlign: 'center' }}>
            <h3>
                {renderIcon()}
                {text}
            </h3>
        </div>
    );
};

export default Alert;

Alert.propTypes = {
    icon: PropTypes.string,
    type: PropTypes.string,
    text: PropTypes.string,
};
