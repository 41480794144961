/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';

export const triggerList = [
    {
        id: 'AirPollution',
        label: 'Air Pollution',
        subtitle: '',
        info: (
            <ul>
                <li>
                    Check the pollen count every day. You can do this by using an app, checking a weather website, or
                    watching the weather on TV.
                </li>
                <li>
                    {`When the pollen count is high, stay indoors as much as possible and keep your windows closed. (If it's very hot out and you don't have air conditioning, do not close your windows.)`}
                </li>
                <li>
                    {`If you've been outside, change clothes and shower and wash your hair before going to bed to get
                    the pollen off.`}
                </li>
                <li>{`Don't wear clothes or sleep on sheets that have been dried outside.`}</li>
            </ul>
        ),
    },
    {
        id: 'Animals',
        label: 'Furry or Feathered Animals',
        subtitle: 'Cats, Dogs, Birds, Horses, Cows, Mice, Rats, Hamsters, etc.',
        info: (
            <ul>
                <li>
                    {`If animals trigger your asthma symptoms, remove pets from your home. If that's not possible, at
                    least keep them out of your bedroom.`}
                </li>
                <li>
                    {`Try to avoid visiting homes that have pets. If you can't do this, make sure you take your
                    quick-reliever pump with you when you visit someone who has a pet and use it 15 minutes before you
                    get to their house.`}
                </li>
                <li>
                    If your house is heated with forced air (you have vents that the heat comes through instead of
                    radiators or baseboard heaters), talk to your parents about getting a HEPA filter for your heating
                    system.
                </li>
                <li>
                    If you have mice in your house, talk to your parents about getting rid of them. (See the full
                    triggers info handout on your Dashboard for tips on the best ways to deal with mice.)
                </li>
            </ul>
        ),
    },
    {
        id: 'Colds',
        label: 'Cold/Flu/Upper Respiratory Infections',
        subtitle: '',
        info: (
            <ul>
                <li>Stay away from people who have the flu.</li>
                <li>Get a flu shot.</li>
                <li>Wash your hands frequently.</li>
            </ul>
        ),
    },
    {
        id: 'Dust',
        label: 'Indoor Dust/Dust Mites',
        subtitle: '',
        info: (
            <ul>
                <li>
                    {`Dust mites love bedding and stuffed toys, so don't keep stuffed animals on your bed. Also, cover
                    your mattress and box spring in mite-proof covers. These covers stop you from breathing in the dust
                    mites when you sleep. Check out the Resources tab for a link to places that sell these covers, as
                    well as other asthma-friendly products.`}
                </li>
                <li>
                    Wash your pillow cases, sheets, blankets, dust ruffle (if you have one), and comforter in hot water
                    every week, and dry them on the highest heat setting available. (See the full triggers info handout
                    on your Dashboard for more tips on washing your bedding.)
                </li>
                <li>{`Don't sit or lie on upholstered (cloth-covered) furniture.`}</li>
                <li>If possible, get rid of carpeting and area rugs.</li>
                <li>
                    Dust mites love warm, humid conditions. So talk to your parents about keeping the temperature in
                    your house below 70 degrees and keeping the humidity below 50%. (See the full triggers info handout
                    on your Dashboard for tips on lowering the humidity in your house.)
                </li>
            </ul>
        ),
    },
    {
        id: 'Exercise',
        label: 'Exercise',
        subtitle: '',
        info: (
            <ul>
                <li>
                    Work out a plan with your provider so that you can exercise without symptoms. Talk to him or her
                    about what sports might work for you.
                </li>
                <li>Use your quick reliever before you exercise.</li>
                <li>
                    For some people, doing a slow warm up and/or cool down before or after active exercises or sports
                    can decrease symptoms.
                </li>
            </ul>
        ),
    },
    {
        id: 'Feathers',
        label: 'Feathers',
        subtitle: 'Down Jackets or Bedding',
        info: (
            <ul>
                <li>{`Use pillows and bedding that don't have feathers.`}</li>
                <li>Avoid wearing down coats.</li>
            </ul>
        ),
    },
    {
        id: 'Gas',
        label: 'Gas Cooking/Heat Sources',
        subtitle: 'Gas Stove or Oven, Kerosene Heater or Gas Space Heater',
        info: (
            <div>
                <strong>1. Gas stove:</strong>
                <ul>
                    <li>Open a window or door to the outside a little to allow fresh air in.</li>
                    <li>Use a hood fan if possible.</li>
                </ul>
                <strong>2. Kerosene and gas space heaters, wood or pellet stoves, and gas fireplaces:</strong>
                <ul>
                    <li>Keep doors open to the rest of the house so the air can move around.</li>
                    <li>Open a window or door to the outside a little to allow fresh air in.</li>
                </ul>
            </div>
        ),
    },
    {
        id: 'Mold',
        label: 'Indoor and Outdoor Mold',
        subtitle: '',
        info: (
            <ul>
                <li>{`Get your parents to fix leaky faucets and pipes. Mold grows when it's damp.`}</li>
                <li>Avoid humidifiers and vaporizers. These add moisture to the air.</li>
                <li>
                    Keep indoor humidity below 50%. (See the full triggers info handout on your Dashboard for tips on
                    lowering the humidity in your house.)
                </li>
                <li>
                    {`If you've had flooding in your house, you'll have to take some extra steps to deal with mold. (See
                    the full triggers info handout on your Dashboard for tips on dealing with mold.)
                `}
                </li>
                <li>{`Because mold grows on fallen leaves and grass clippings, you shouldn't rake these things up.`}</li>
            </ul>
        ),
    },
    {
        id: 'Odors',
        label: 'Strong Odors',
        subtitle: 'Hairspray, Air Fresheners, Cleaning Products, Pig/Cow Farm, Waste Dump',
        info: (
            <ul>
                <li>Avoid paint, perfume, and perfumed cosmetics.</li>
                <li>
                    {`Don't use things that are scented. This includes cleaning products, incense, and candles. White
                    vinegar and baking soda are effective cleaners that remove odors and won't trigger your asthma
                    symptoms.`}
                </li>
                <li>Avoid air fresheners. Instead, open shades and windows and use a fan to circulate fresh air.</li>
                <li>
                    When you cook, use a fan or open a window to get rid of strong odors, especially when frying foods.
                </li>
            </ul>
        ),
    },
    {
        id: 'Pesticides',
        label: 'Pesticides and Other Chemicals',
        subtitle: '',
        info: (
            <>
                <strong>1. Home pesticides:</strong>
                <ul>
                    <li>
                        Instead of spraying bugs, try to control them by not leaving food or water out or leaving
                        garbage open.
                    </li>
                    <li>
                        Instead of spraying spiders, leave them alone. If you have to get rid of them, vacuum them up.
                    </li>
                    <li>
                        If you must use pesticides, read the directions carefully and stay away from where you have
                        sprayed until the pesticide dries.
                    </li>
                    <li>{`Don't spray outdoors on windy or rainy days.`}</li>
                </ul>
                <strong>2. Farm pesticides:</strong>
                <ul>
                    <li>
                        When these chemical sprays are being used near your home, you should: keep windows shut, turn
                        off circulating fans, turn off heating and air conditioning units, reduce time outside, do not
                        dry clothes outside. (See the full triggers info handout on your Dashboard for more tips on
                        dealing with pesticides and other chemicals.)
                    </li>
                </ul>
            </>
        ),
    },
    {
        id: 'Pollen',
        label: 'Pollen',
        subtitle: '',
        info: (
            <ul>
                <li>
                    Check the pollen count every day. You can do this by using an app, checking a weather website, or
                    watching the weather on TV.
                </li>
                <li>
                    {`When the pollen count is high, stay indoors as much as possible and keep your windows closed. (If it's very hot out and you don't have air conditioning, do not close your windows.)`}
                </li>
                <li>
                    {`If you've been outside, change clothes and shower and wash your hair before going to bed to get
                    the pollen off.`}
                </li>
                <li>{`Don't wear clothes or sleep on sheets that have been dried outside.`}</li>
            </ul>
        ),
    },
    {
        id: 'Roaches',
        label: 'Roaches',
        subtitle: '',
        info: (
            <ul>
                <li>
                    {`Roaches are attracted to food and water, so don't leave food or water out or leave garbage open.`}
                </li>
                <li>Get your parents to fix leaky faucets and pipes.</li>
                <li>Talk to your parents about plugging holes and cracks along baseboards.</li>
                <li>
                    Talk to your parents about using bait traps, pesticide gels, boric acid, or glue traps to kill
                    roaches. You can get these at hardware stores. Keep these away from children and pets.
                </li>
                <li>Do not use spray pesticides - they can trigger your asthma.</li>
            </ul>
        ),
    },
    {
        id: 'Smoke',
        label: 'Smoke',
        subtitle: 'Cigarette, Cigar, Marijuana, Hookah, E-Cigarette Vapor, Wood Fires',
        info: (
            <ul>
                <li>
                    {`Don't smoke cigarettes - or anything else! That includes cigars, marijuana, e-cigarettes, or hookah
                    pipes. If you do smoke and need help quitting, talk to your health care provider. You'll also find
                    materials that can help you quit in the Resources section.`}
                </li>
                <li>Avoid being near people who smoke.</li>
                <li>
                    {`Encourage friends and family members to not smoke near you or at any time in your home or car.
                    Explain that you have asthma and the smoke makes it worse. You can also tell them that smoking in
                    your home or car, even when you're not there, is bad for your asthma. Tobacco smoke leaves behind
                    poisons that can't be seen, and these poisons can trigger your asthma symptoms.`}
                </li>
                <li>
                    Avoid wood fires. If you have to build one, use dry seasoned wood only. Keep the fire hot. This will
                    limit the amount of smoke the fire produces. If you are inside, crack a window or door to allow
                    fresh air in.
                </li>
            </ul>
        ),
    },
    {
        id: 'Weather',
        label: 'Weather',
        subtitle: 'Cold Air, Heat, Thunderstorms',
        info: (
            <ul>
                <li>Wear a scarf over your mouth and nose in cold weather.</li>
                <li>Avoid hard exercise outdoors in cold weather.</li>
                <li>
                    Thunderstorms can trigger your asthma because they increase pollen and spore levels. To help control
                    your asthma, stay inside before, during, and after the storm and keep your windows closed.
                </li>
                <li>
                    When it is very hot out, try to stay in air conditioned buildings and avoid exercising outdoors.
                </li>
            </ul>
        ),
    },
];
