import React from 'react';
import { LoginForm } from 'cccisd-laravel-nexus';
import { Link } from 'react-router-dom';
import _find from 'lodash/find';

import logo from '../../../images/reportImages/circleLogo.png';
import style from './style.css';

var Fortress = window.cccisd.fortress;
var AppDefs = window.cccisd.appDefs;

const Welcome = () => {
    let homepageRoute = _find(AppDefs.routes, r => r.handle === AppDefs.navs[0].homepage);

    return (
        <div>
            <div className={style.login}>
                <div className={style.logo}>
                    <img src={logo} alt="Camp Air circle logo" className={style.circle} />
                </div>
                <div className={style.welcomeBox}>
                    {Fortress.auth() ? (
                        <div className={style.loginForm}>
                            <p>Hello, {Fortress.user.username}</p>
                            {homepageRoute && (
                                <div>
                                    <Link to={homepageRoute.url}>
                                        <button type="button" className="btn btn-info" style={{ width: '100%' }}>
                                            Go To Home
                                        </button>
                                    </Link>
                                </div>
                            )}
                        </div>
                    ) : (
                        <LoginForm showPasswordToggle />
                    )}
                </div>
            </div>
            <div className={style.scene}>
                <div className={style.flag}>
                    <img
                        src="https://campairprogram.com/resources/asma/images/login/Flag_01.gif"
                        alt="Camp Air flag blowing in breeze"
                        width="208px"
                        height="432px"
                    />
                </div>
                <div className={style.house}>
                    <img src="https://campairprogram.com/resources/asma/images/login/house.png" alt="house" />
                </div>
                <div className={style.tree}>
                    <img src="https://campairprogram.com/resources/asma/images/login/tree.png" alt="tree" />
                </div>
            </div>
        </div>
    );
};

export default Welcome;
