import { format, subDays, parseISO } from 'date-fns';
import { prev30days, reportItems, getWarnings } from '../../../../../pages/TwoDayChecklist/helpers.js';

export default (data, retroCompletedDates, currentPart) => {
    const allDays = data
        .map(day => {
            return { date: day.date, content: JSON.parse(day.content) };
        })
        .sort((a, b) => new Date(a.date) - new Date(b.date));

    const organizeDataByTimeChunks = previousDays => {
        return allDays.filter(value => previousDays.includes(value.date));
    };

    const getFinishedParts = startingDay => {
        // get most recent day and 6 day previous days' data
        const mostRecentDayIndex = startingDay;

        if (mostRecentDayIndex === -1) {
            return false;
        }
        const previous7DaysOfData = allDays.slice(mostRecentDayIndex - 6, mostRecentDayIndex + 1);

        // make an array of today and 6 days previous calendar days
        const finishedAPartDate = allDays[mostRecentDayIndex].date;

        const getSevenCalenderDaysBeforeFinishedDate = () => {
            const parseFirst = parseISO(finishedAPartDate);

            const sevenDaysBefore = [0, 1, 2, 3, 4, 5, 6].map(i => {
                const timeStamp = subDays(parseFirst, i);

                return format(timeStamp, 'yyyy-MM-dd');
            });

            return sevenDaysBefore;
        };

        const sevenDaysInARow = getSevenCalenderDaysBeforeFinishedDate();

        // compare the 2 arrays and make sure they have the same dates
        const sevenConsecutiveDaysDataCheck =
            previous7DaysOfData.filter(value => sevenDaysInARow.includes(value.date)).length === 7
                ? previous7DaysOfData.filter(value => sevenDaysInARow.includes(value.date))
                : false;

        return sevenConsecutiveDaysDataCheck ? sevenConsecutiveDaysDataCheck : false;
    };

    const thirtyDays = organizeDataByTimeChunks(prev30days).filter(hex => hex.content);

    const totals = (value, dayTotal, weekData) => {
        if (dayTotal === 'seven') {
            return weekData.filter(day => day.content[value] === 'Yes').length;
        }

        if (dayTotal === 'thirty') {
            return thirtyDays.filter(day => day.content[value] === 'Yes').length;
        }
    };

    let showPart2 = true;
    let showPart3 = false;
    let showPart4 = false;
    let showPart5 = false;
    let showPart6 = false;
    let showPart7 = false;

    // We already had to hardcode the query for this report (see oneLearnerChecklists.graphql) so doing it again here to distinguish between each module.
    switch (currentPart) {
        case 21:
            showPart3 = true;
            break;
        case 22:
            showPart3 = true;
            showPart4 = true;
            break;
        case 23:
            showPart3 = true;
            showPart4 = true;
            showPart5 = true;
            break;
        case 24:
            showPart3 = true;
            showPart4 = true;
            showPart5 = true;
            showPart6 = true;
            break;
        case 25:
            showPart3 = true;
            showPart4 = true;
            showPart5 = true;
            showPart6 = true;
            showPart7 = true;
            break;
        default:
            showPart2 = true;
            break;
    }

    // module 2 totals
    const part2StartDay = allDays.findIndex(
        day => day.date === retroCompletedDates.part2retro.entityState.completedFirstDate
    );

    const part2 = part2StartDay !== -1 && getFinishedParts(part2StartDay);

    const part2Totals =
        part2 &&
        reportItems.reduce((obj, item) => {
            obj[item] = totals(item, 'seven', part2);
            return obj;
        }, {});

    // module 3 totals
    const part3StartDay = allDays.findIndex(
        day => day.date === retroCompletedDates.part3retro.entityState.completedFirstDate
    );

    const part3 = part2 && part3StartDay !== -1 && getFinishedParts(part3StartDay);

    const part3Totals =
        part3 &&
        reportItems.reduce((obj, item) => {
            obj[item] = totals(item, 'seven', part3);
            return obj;
        }, {});

    // module 4 totals
    const part4StartDay = allDays.findIndex(
        day => day.date === retroCompletedDates.part4retro.entityState.completedFirstDate
    );

    const part4 = part3 && part4StartDay !== -1 && getFinishedParts(part4StartDay);

    const part4Totals =
        part4 &&
        reportItems.reduce((obj, item) => {
            obj[item] = totals(item, 'seven', part4);
            return obj;
        }, {});

    // module 5 totals
    const part5StartDay = allDays.findIndex(
        day => day.date === retroCompletedDates.part5retro.entityState.completedFirstDate
    );

    const part5 = part4 && part5StartDay !== -1 && getFinishedParts(part5StartDay);

    const part5Totals =
        part5 &&
        reportItems.reduce((obj, item) => {
            obj[item] = totals(item, 'seven', part5);
            return obj;
        }, {});

    // module 6 totals
    const part6StartDay = allDays.findIndex(
        day => day.date === retroCompletedDates.part6retro.entityState.completedFirstDate
    );

    const part6 = part5 && part6StartDay !== -1 && getFinishedParts(part6StartDay);

    const part6Totals =
        part6 &&
        reportItems.reduce((obj, item) => {
            obj[item] = totals(item, 'seven', part6);
            return obj;
        }, {});

    // module 7 totals
    const part7StartDay = allDays.findIndex(
        day => day.date === retroCompletedDates.part7retro.entityState.completedFirstDate
    );

    const part7 = part6 && part7StartDay !== -1 && getFinishedParts(part7StartDay);

    const part7Totals =
        part7 &&
        reportItems.reduce((obj, item) => {
            obj[item] = totals(item, 'seven', part7);
            return obj;
        }, {});

    const getReportDateRange = weekData => {
        const parseFirstDate = parseISO(weekData[0].date);
        const formattedFirst = format(parseFirstDate, 'MMM dd');

        const parseLastDate = parseISO(weekData[weekData.length - 1].date);
        const formattedLast = format(parseLastDate, 'MMM dd');

        return `${formattedFirst} to ${formattedLast}`;
    };

    const thirtyDayTotals = reportItems.reduce((obj, item) => {
        obj[item] = totals(item, 'thirty');
        return obj;
    }, {});

    const getWarningTotals = weekTotal => getWarnings(weekTotal, thirtyDayTotals);

    const addDay = day => +day * 17;

    const totalDays = day => (7 - +day) * 17;

    const getLineChartSettings = (weekTotals, id, key, daysExperiencedBackground, noDaysBackground) => {
        return {
            id,
            daysExperienced: weekTotals[key],
            daysExperiencedFlexBasis: `${addDay(weekTotals[key])}%`,
            daysExperiencedBackground,
            noDaysBackground,
            noDaysFlexBasis: `${totalDays(weekTotals[key])}%`,
        };
    };

    const organizeWeekData = (weekTotals, weekArray) => {
        return {
            warningTotals: getWarningTotals(weekTotals),

            firstDay: weekArray[0].date,
            reportDateRange: getReportDateRange(weekArray),

            controller: getLineChartSettings(weekTotals, 'Long-term controller', 'Controller med', 'maroon', 'tomato'),
            symptoms: getLineChartSettings(weekTotals, 'Symptoms', 'Symptoms', '#017979', '#8ed3d9'),
            nights: getLineChartSettings(weekTotals, 'Night Awakenings', 'Waking at night', '#017979', '#8ed3d9'),
            activities: getLineChartSettings(weekTotals, 'Activities Stopped', 'Interruptions', '#017979', '#8ed3d9'),
            reliever: getLineChartSettings(
                weekTotals,
                'Quick Reliever to Treat Symptoms',
                'Quick reliever',
                '#017979',
                '#8ed3d9'
            ),

            weekTotals,
        };
    };

    const weeks = {
        weekBeforePart2: part2 && showPart2 && organizeWeekData(part2Totals, part2),
        weekBeforePart3: part3 && showPart3 && organizeWeekData(part3Totals, part3),
        weekBeforePart4: part4 && showPart4 && organizeWeekData(part4Totals, part4),
        weekBeforePart5: part5 && showPart5 && organizeWeekData(part5Totals, part5),
        weekBeforePart6: part6 && showPart6 && organizeWeekData(part6Totals, part6),
        weekBeforePart7: part7 && showPart7 && organizeWeekData(part7Totals, part7),
    };

    const getTableRows = rowItems => {
        return rowItems.map(rowItem => {
            return {
                key: rowItem.id,
                id: rowItem.id,
                part2: part2 && showPart2 && weeks.weekBeforePart2.weekTotals[`${rowItem.item}`],
                part3: part3 && showPart3 && weeks.weekBeforePart3.weekTotals[`${rowItem.item}`],
                part4: part4 && showPart4 && weeks.weekBeforePart4.weekTotals[`${rowItem.item}`],
                part5: part5 && showPart5 && weeks.weekBeforePart5.weekTotals[`${rowItem.item}`],
                part6: part6 && showPart6 && weeks.weekBeforePart6.weekTotals[`${rowItem.item}`],
                part7: part7 && showPart7 && weeks.weekBeforePart7.weekTotals[`${rowItem.item}`],
            };
        });
    };

    return {
        weeks,
        controllerTableRows: getTableRows([
            { id: 'Number of days you took your long-term controller', item: 'Controller med' },
        ]),
        asthmaControlTableRows: getTableRows([
            { id: 'Number of days you had activities stopped because of symptoms', item: 'Interruptions' },
            { id: 'Number of days you had symptoms during the day', item: 'Symptoms' },
            { id: 'Number of nights you woke at night because of asthma symptoms', item: 'Waking at night' },
            { id: 'Number of days you took your quick reliever to treat symptoms', item: 'Quick reliever' },
        ]),
        triggerTableRows: getTableRows([
            {
                id: 'Number of days you took your long-term controller',
                item: 'Controller med',
            },
            {
                id: 'Number of days you had symptoms',
                item: 'Symptoms',
            },
            {
                id: 'Number of days symptoms were triggered by:',
            },
            {
                id: 'Air pollution',
                item: 'Air pollution',
            },
            {
                id: 'Animals',
                item: 'Animals',
            },
            {
                id: 'Dust',
                item: 'Dust/dust mites',
            },
            {
                id: 'Environment',
                item: 'Environment',
            },
            {
                id: 'Feathers',
                item: 'Feathers',
            },
            {
                id: 'Gas heat sources',
                item: 'Gas heat sources',
            },
            {
                id: 'Had flu or cold',
                item: 'Had flu or cold',
            },
            {
                id: 'Mold',
                item: 'Mold',
            },
            {
                id: 'Pesticides',
                item: 'Pesticides',
            },
            {
                id: 'Pollen',
                item: 'Pollen',
            },
            {
                id: 'Roaches',
                item: 'Roaches',
            },
            {
                id: 'Smoke',
                item: 'Smoke',
            },
            {
                id: 'Strong odors',
                item: 'Strong odors',
            },
            {
                id: 'Weather',
                item: 'Weather',
            },
            {
                id: 'Not Specified',
                item: 'Other',
            },
        ]),
    };
};
